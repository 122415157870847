import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { hooks } from "botframework-webchat-component";
import { setAlert } from "store/slice/alertSlice";
import {
  setConversation,
  setOnGoingConversationId,
} from "store/slice/conversationSlice";

import { setUpdateConversations } from "store/slice/saveConversationsSlice";

import { setHeaderTitle } from "store/slice/headerSlice";
import {
  reva_conversation_base_url,
  reva_message_base_url,
} from "utilities/apiRoutes";
import useRefreshToken from "./useRefreshToken";
import useSpeechToText from "./useSpeechToText";
import { trackBotConversation, trackNewConversationByUser, trackQuestionsAskedByUser } from "utilities/mixPanel";
import { setBotMessage } from "../store/slice/bootMessageSlice";
import { capitalizeFirstLetter } from "utilities/commonMethod";


const { useActivities, usePostActivity } = hooks;
const useChatBot = (resetMessageField) => {
  const { accessTokenInterceptor } = useRefreshToken();
  const [activities] = useActivities();

  const postActivity = usePostActivity();

  const [isMessagesLoading, setIsMessagesLoading] = useState(false);
  const [isConversationCreated, setIsConversationCreated] = useState(false);
  // const [sendBoxValue, setSendBoxValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSummaryNameSet, setIsSummaryNameSet] = useState(false);
  const [conversationSummary, setConversationSummary] = useState("");
  const [messages, setMessages] = useState([]);
  const dispatch = useDispatch();
  const conversation = useSelector((state) => state.conversation);
  const { conversationText } = useSelector((state) => state.conversation);
  const userInfo = useSelector((state) => state.userInfo);
  const { transcript } = useSpeechToText();
  const { selectedConversation } = useSelector(
    (state) => state.savedConversations
  );
  const { isChatHistory } = useSelector((state) => state.userInfo);
  const botField = useRef(null);
  const handleOnBlur = () => {
    dispatch(
      setConversation({
        fieldFocus: false,
        conversationText: "",
      })
    );
  };
  const regenerateResponse = () => {
    const userMessages = activities.filter(
      (activity) => activity?.from?.role === "user"
    );
    postActivityToBot(userMessages[userMessages.length - 1].text);
  };
  useEffect(() => {
    if (conversation.fieldFocus) {
      botField.current.focus();
    }
  }, [conversation]);
  // useEffect(() => {
  //   // setSendBoxValue(conversation.conversationText);
  //   setConversationSummary(conversation.conversationText);
  //   postActivityToBot(conversation.conversationText);
  // }, [conversation.conversationText]);

  useEffect(() => {
    checkIfWaitingForBotResponse(activities);
    async function updateSaveConversations(activity) {
      if (!activity.length) return;
      await createConversation(activity);
      await updatedConversationName(activities);
    }

    updateSaveConversations(activities);
  }, [activities.length]);
  const id = activities.at(-1)?.id;
  useEffect(() => {
    if (isChatHistory === "true") {
      updateCurrentConversation(activities);
    }
  }, [id,activities]);

  function checkIfWaitingForBotResponse(activity) {
    if (activity.length && activity?.at(-1)?.from.role === "user") {
      setIsLoading(true);
      return;
    }
    setIsLoading(false);
  }

  useEffect(() => {
    async function getMessages() {
      if (selectedConversation === null) return;
      botField.current.focus();
      dispatch(setHeaderTitle(selectedConversation.name));
      try {
        setIsMessagesLoading(true);
        const { data } = await accessTokenInterceptor.get(
          `${reva_message_base_url}/conversations/${selectedConversation.conversationId}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.accessToken}`,
            },
          }
        );

        if (data.length) {
          setMessages(data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsMessagesLoading(false);
      }
    }
    getMessages();
  }, [selectedConversation]);

  const handleChange = ({ target: { value } }) => {
    dispatch(
      setConversation({
        conversationText: value,
        fieldFocus: true,
      })
    );
  };

  const handleSubmit = async (event) => {
    // this will submit the input to receive a response from the bot
    event.preventDefault();
    if (isLoading) return;
    if (conversationText === "") return;
    if (conversationText.length && conversationText.trim().length === 0) {
      dispatch(
        setAlert({
          title: "Please enter a message",
          type: "warning",
        })
      );
      dispatch(
        setConversation({
          conversationText: "",
          fieldFocus: true,
        })
      );
      return;
    }
    // sendMessage(sendBoxValue);
    postActivityToBot(conversationText);
    if (conversationSummary === "") {
      setConversationSummary(conversationText.trim());
    }
    dispatch(
      setConversation({
        conversationText: "",
        fieldFocus: true,
      })
    );
    botField.current.focus();
  };
  function postActivityToBot(conversationText) {
    resetMessageField();
    let activity = {
      type: "message",
      text: capitalizeFirstLetter(conversationText),
    };
    if (activities.length === 0) {
      activity = {
        ...activity,
        chatTitle: "True",
        user_info: {
          name: userInfo.name,
          phone: userInfo.phoneNumber,
          email: userInfo.email,
          uid: userInfo.userID,
          isMessagePreference: userInfo.isMessagePreferences,
        }
      }
    } else {
      activity = {
        ...activity,
        user_info: {
          name: userInfo.name,
          phone: userInfo.phoneNumber,
          email: userInfo.email,
          uid: userInfo.userID,
        }
      }
    };
    if (conversationText.length > 0) {

      trackQuestionsAskedByUser(userInfo.email, conversationText)
    }

    postActivity(activity);

  }
  const createConversation = async (activity) => {
    // this will send the first activity conversationId to the server to create a conversation

    if (selectedConversation !== null) return;
    if (!conversationSummary) return;
    if (isConversationCreated) return;
    try {
      const { data } = await accessTokenInterceptor.post(
        reva_conversation_base_url,
        {
          conversationId: activity[0]?.conversation.id,
          userId: userInfo.userID,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.accessToken}`,
          },
        }
      );
      if (data) {
        setIsConversationCreated(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updatedConversationName = async (activities) => {
    // this will send the second activity text to the server to update the conversation name

    if (selectedConversation !== null) return;
    if (activities.length < 2) return;
    if (isSummaryNameSet) return;
    dispatch(setHeaderTitle(activities[1].value.chatTitle));
    trackNewConversationByUser(userInfo.email)
    // * mix panel tracking event for bot conversation removed for production release
    trackBotConversation(
      userInfo.email,
      activities[0].conversation.id,
      activities[1].value.chatTitle
    );
    try {
      const { data } = await accessTokenInterceptor.patch(
        `${reva_conversation_base_url}`,
        {
          name: activities[1].value.chatTitle,
          conversationId: activities[0].conversation.id,
          userId: userInfo.userID,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.accessToken}`,
          },
        }
      );

      if (data) {
        if (isChatHistory === "true") {
          dispatch(setUpdateConversations(data));
        }
        dispatch(setOnGoingConversationId(data.conversationId));
        setIsSummaryNameSet(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateCurrentConversation = async (activity) => {
    // this will update each activity to the server-
    if (activity.at(-1).from.name) {
      const bootMessages = [];
      for (let i = activities.length - 1; i > 0; i--) {

        if (activities[i].from?.role === "bot") {
          bootMessages.push(activities[i].text)
        } else {
          break;
        };
      }

      dispatch(setBotMessage(bootMessages))
    }
    if (isMessagesLoading) return;
    
    if (activity.length <= messages.length) return;

    if (!activity.length > 0 && activity.at(-1)?.channelId === "webchat")
      return;
    try {
      await accessTokenInterceptor.post(
        `${reva_message_base_url}`,
        {
          conversationId: activity[0]?.conversation?.id,
          from: {
            ...activity.at(-1).from,
            name: activity.at(-1).from.name
              ? activity.at(-1).from.name
              : "user",
          },
          text: activity.at(-1).text,
          id: activity.at(-1).id,
          timestamp: activity.at(-1).timestamp,
          userId: userInfo.userID
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.accessToken}`,
          },
        }
      );
    } catch (error) {
      // console.log(error);
    }
  };

  return {
    handleChange,
    handleSubmit,
    activities,
    messages,
    handleOnBlur,
    conversation,
    botField,
    regenerateResponse,
    botConversations: isLoading
      ? [
        ...messages,
        ...activities.slice(messages.length, undefined),
        ...[
          {
            type: "typing",
            text: "REGA is typing...",
            from: {
              role: "bot",
              name: "bot",
            },
          },
        ],
      ]
      : [...messages, ...activities.slice(messages.length, undefined)],
    isLoading,
    selectedConversation,
  };
};

export default useChatBot;
