import { createSlice } from "@reduxjs/toolkit";

// * in axios headers cIdToken will be sent as c-id-token and cRefreshToken will be sent as c-refresh-token

const initialState = {
  cIdToken: "",
  cRefreshToken: "",
  accessToken: "",
  email: "",
  userID: "",
  createdAt: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  isDataConsent: "",
  isChatHistory: "",
  isMessagePreferences:""
};

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.cIdToken = action.payload.cIdToken;
      state.cRefreshToken = action.payload.cRefreshToken;
      state.accessToken = action.payload.accessToken;
      state.email = action.payload.email;
      state.userID = action.payload.userID;
      state.createdAt = action.payload.createdAt;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName || "";
      state.phoneNumber = action.payload.phoneNumber || "";
    },
    setResetUserInfo: (state, action) => {
      state.cIdToken = "";
      state.cRefreshToken = "";
      state.accessToken = "";
      state.email = "";
      state.userID = "";
      state.createdAt = "";
      state.firstName = "";
      state.lastName = "";
      state.phoneNumber = "";
      state.isChatHistory = "";
      state.isDataConsent = "";
      state.isMessagePreferences = "";
    },
    setUpdateTokens: (state, action) => {
      state.cIdToken = action.payload.cIdToken;
      state.cRefreshToken = action.payload.cRefreshToken;
      state.accessToken = action.payload.accessToken;
    },
    setUpdateUserDetails: (state, action) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.phoneNumber = action.payload.phoneNumber;
    },
    setUserDataConsent: (state, action) => {
      state.isDataConsent = action.payload.isDataConsent || "";
    },
    setUserChatHistory: (state, action) => {
      state.isChatHistory = action.payload.isChatHistory || "";
    },
    setUserMessagePreferences: (state, action) => {
      state.isMessagePreferences = action.payload.isMessagePreferences || "";
    }
  },
});

export const {
  setUserInfo,
  setResetUserInfo,
  setUpdateTokens,
  setUpdateUserDetails,
  setUserDataConsent,
  setUserChatHistory,
  setUserMessagePreferences
} = userInfoSlice.actions;

export default userInfoSlice.reducer;
