import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  message: null, // message could be anything like a string, object, array, etc.
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModalOpen: (state, action) => {
      state.name = action.payload.name;
      
    },
    setModalMessage:(state,action)=>{
      state.message = action.payload.message;
    }
  },
});

export const { setModalOpen } = modalSlice.actions;
export const { setModalMessage } = modalSlice.actions;

export default modalSlice.reducer;
