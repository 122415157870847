import { sellerFAQ } from 'assets/data/data'
import FaqAccordion from 'components/FaqAccordion'
import React from 'react'

export function SellersFAQ() {
  return (
   <>
   <h2 className='ms-2 mt-4 faqs-heading'>Seller FAQs</h2>
   <FaqAccordion faqs={sellerFAQ}/>
   </>
  )
}
