// always add / before any new route
export const routes = {
  main: "/",
  aboutUs: "/about-us",
  login: "/login",
  signup: "/signup",
  tools: "/tools",
  faq: "/faq",
  typography: "/typography",
  updatePassword: "/updatePassword"
};

// FAQ page nested routes
export const faqNestedRoutes = {
  buyer: "/buyer",
  seller: "/seller",
  renter: "/renter",
  legal: "/legal",
  cityscape: "/cityscape",
  recruitment: "/recruitment",
  reva: "/reva",
};
