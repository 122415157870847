import Modal from "components/Modal";
import { useDispatch } from "react-redux";
import { setModalOpen } from "store/slice/modalSlice";
import useSavedConversation from "hooks/useSavedConversation";



export const ClearConversationByIdModal = ({ isVisible, disconnect ,key}) => {
    const {
        handleRemoveConversationById,
    } = useSavedConversation();
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setModalOpen({ name: "" }));
    };
    return (
        <Modal title="Confirm delete" show={isVisible}>
            <div className="mt-px-10">
                <p>
                    Are you sure you want to delete this chat? This action cannot be reversed.
                </p>
                <div className="mt-px-25 d-flex justify-content-end gap-2">
                    <button className="btn  bg-white text-primary" onClick={handleClose}>
                        Cancel
                    </button>
                    <button className="btn btn-primary" onClick={() => handleRemoveConversationById(disconnect,key)}>
                        Delete
                    </button>
                </div>
            </div>
        </Modal >
    );
};
