import React from "react";
import { Form, Spinner, Button } from "react-bootstrap";
import useChatBot from "hooks/useChatBot";
import ExampleQuestions from "components/ExampleQuestions";
import Icon from "components/IconMoon/Icon";
import Conversation from "components/Conversation";
import useSpeechToText from "hooks/useSpeechToText";
import Tooltip from "components/Tooltip";
import SpeechRecognition from "react-speech-recognition";
import { useSelector } from "react-redux";
import gifMicrophone from "assets/gif/microphone.gif";
export const ChatBot = () => {
  const { conversationText } = useSelector((state) => state.conversation);

  const { handleSpeechRecognition, stopListener, resetTranscript, listening ,checkIdolStateForListening } =
    useSpeechToText();
  const resetMessageField = () => {
    resetTranscript();
    SpeechRecognition.stopListening();
    stopListener();
  };
  const {
    handleSubmit,
    handleChange,
    botField,
    botConversations,
    isLoading,
    selectedConversation,
    regenerateResponse,
  } = useChatBot(resetMessageField);
  const handleMicrophone = () => {
    checkIdolStateForListening();
    handleSpeechRecognition();
  }
  const MicSvgIcon = (props) => (
    <svg
      width="24"
      height="29"
      viewBox="0 0 24 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M17.5 15H17C16.7188 15 16.5 15.25 16.5 15.5V17C16.5 19.3438 14.4688 21.2188 12.0938 21C10 20.7812 8.5 18.9375 8.5 16.8438V15.5C8.5 15.25 8.25 15 8 15H7.5C7.21875 15 7 15.25 7 15.5V16.7812C7 19.5625 8.96875 22.0625 11.75 22.4375V23.5H10C9.71875 23.5 9.5 23.75 9.5 24V24.5C9.5 24.7812 9.71875 25 10 25H15C15.25 25 15.5 24.7812 15.5 24.5V24C15.5 23.75 15.25 23.5 15 23.5H13.25V22.4688C15.9062 22.0938 18 19.7812 18 17V15.5C18 15.25 17.75 15 17.5 15ZM12.5 20C14.1562 20 15.5 18.6875 15.5 17V12C15.5 10.3438 14.1562 9 12.5 9C10.8125 9 9.5 10.3438 9.5 12V17C9.5 18.6875 10.8125 20 12.5 20ZM11 12C11 11.1875 11.6562 10.5 12.5 10.5C13.3125 10.5 14 11.1875 14 12H12.75C12.5938 12 12.5 12.125 12.5 12.25V12.75C12.5 12.9062 12.5938 13 12.75 13H14V14H12.75C12.5938 14 12.5 14.125 12.5 14.25V14.75C12.5 14.9062 12.5938 15 12.75 15H14V16H12.75C12.5938 16 12.5 16.125 12.5 16.25V16.75C12.5 16.9062 12.5938 17 12.75 17H14C14 17.8438 13.3125 18.5 12.5 18.5C11.6562 18.5 11 17.8438 11 17V12Z"
        fill="#1E1E1E"
      />
      <path
        d="M8 13V11.5C8 9.01472 10.0147 7 12.5 7V7C14.9853 7 17 9.01472 17 11.5V13"
        stroke="#ffffff"
        strokeLinecap="round"
      />
      <path
        d="M5.75 13V10.75C5.75 7.02208 8.77208 4 12.5 4V4C16.2279 4 19.25 7.02208 19.25 10.75V13"
        stroke="#ffffff"
        strokeLinecap="round"
      />
      <path
        d="M3.5 13V10C3.5 5.02944 7.52944 1 12.5 1V1C17.4706 1 21.5 5.02944 21.5 10V13"
        stroke="#ffffff"
        strokeLinecap="round"
      />
    </svg>
  );
  return (
    <>
      {!botConversations.length && selectedConversation === null ? (
        <ExampleQuestions />
      ) : (
        <Conversation activities={botConversations} />
      )}
      <Form
        noValidate
        className="w-100 d-flex justify-content-center flex-column align-items-center"
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        {botConversations.length && !isLoading ? (
          <>
            <Button
              className="modal-btn mt-2 mb-2 small-screen-btn"
              onClick={() => regenerateResponse()}
            >
              <div className="d-flex align-center">
                <Icon
                  icon="redo-alt-1"
                  className="me-2 mt-1"
                  color="red"
                  size={16}
                />
                <p style={{ fontSize: "14px" }}>regenerate response</p>
              </div>
            </Button>
          </>
        ) : null}
        <div
          className="prompt-input-container d-flex flex-column w-100 p-3"
          style={{ background: "#727D8D" }}
        >
          <div
            className="m-auto w-100"
            style={{ maxWidth: "600px", paddingRight: "53px" }}
          >
            <div></div>
            <div className="prompt-input position-relative">
              <input
                type="text"
                placeholder="Ask me anything about real estate in Canada."
                className="form-control bg-white border-white pe-5"
                ref={botField}
                autoFocus
                onChange={handleChange}
                value={conversationText}
                // readOnly={transcript !== ''}
              />
              {isLoading ? (
                <Spinner
                  animation="grow"
                  variant="info"
                  size="sm"
                  className="position-absolute spinner end-0 top-50 translate-middle-y "
                  style={{ marginRight: "10px" }}
                />
              ) : (
                <button
                  type="submit"
                  className="btn btn-sm bg-white position-absolute end-0 top-0 m-1"
                  disabled={isLoading}
                >
                  <Icon icon="paper-plane" size={20} />
                </button>
              )}
              <Tooltip text={listening ? "Turn off Microphone" : "Turn on Microphone"}>
                <button
                  className={`microphone d-flex justify-content-center align-items-center spinner translate-middle-y ${
                    listening && "active"
                  }`}
                  type="button"
                  onClick={handleMicrophone}
                >
                  <img
                    src={gifMicrophone}
                    alt="microphone"
                    className="active"
                    style={{ width: "100%", height: "100%" }}
                  />
                  <MicSvgIcon className="active" />
                </button>
              </Tooltip>
            </div>
            <div></div>
            {/* <p
              className="small text-center pt-2 d-flex justify-content-center gap-3"
              style={{
                color: "var(--gray-300)",
                fontSize: "12px",
                lineHeight: "16px",
              }}
            >
              Tip: the more details you describe above, the better!
              <Icon icon="info-circle" className="text-white" size={16} />
            </p> */}
          </div>
        </div>
      </Form>
    </>
  );
};
