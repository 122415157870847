import { Button } from "react-bootstrap";
import Icon from "components/IconMoon/Icon";
import ConversationNegativeFeedbackModal from "components/ConversationNegativeFeedbackModal";
import useConversation from "hooks/useConversation";
import { useSelector } from "react-redux";
import Tooltip from "components/Tooltip";
import ConversationPositiveFeedbackModal from "components/ConversationPositiveFeedbackModal";
import ChatBotSuggestions from "components/ChatBotSuggestions";
export const Conversation = ({ activities }) => {
  const {
    activityEL,
    feedback,
    isVisible,
    feedbackData,
    handlePositiveFeedback,
    handleOpenFeedbackModal,
    handleNegativeFeedback,
    handleCopy,
    isPositiveFeedbackModal
  } = useConversation(activities);
  const userNameLetter = useSelector((state) =>
    state.userInfo?.firstName?.trim().charAt(0).toUpperCase()
  );
  return (
    <div className="conversation-wrapper overflow-auto scroll-bar">
      <ConversationNegativeFeedbackModal
        isVisible={isVisible}
        negativeFeedbackData={feedbackData}
      />
      <ConversationPositiveFeedbackModal
        isVisible={isPositiveFeedbackModal}
        positiveFeedbackData={feedbackData}
      />
      {activities.map((activity,index) => {
        return (
          <>
            <div
              className={`conversation ${
                activity.from.role === "bot" && "response-bg"
              }`}
              key={activity.id}
            >
              <div className="conversation-inner">
                <div className="d-flex">
                <div
                  className={`avatar ${
                    activity.from.role === "bot" && "bg-black" && "logo"
                  }`}
                >
                  {activity.from.role === "user" ? (
                    <> {userNameLetter} </>
                  ) : (
                    <> <div className="avatar-container">
                      <Icon icon="rega" size={24} />
                    </div>
                    </>
                  )}
                </div>

                <div className="message ">
                  <p className="text-break">{activity.text}</p>
                </div>
                </div>
                
                {activity.type !== "typing" ? (
                  <>
                    {activity.from.role === "bot" ? (
                      <div className="feedback gap-2">
                        {activity.isDisLiked ||
                        feedback.some(
                          (item) => item.id === activity.id && item.isDisLiked
                        ) ? null : (
                          <Tooltip text="Like">
                            <div>
                              <Button
                                variant="link"
                                className="p-0 h-auto border-0"
                                size="sm"
                                onClick={() => handlePositiveFeedback(activity,index)}
                                disabled={
                                  activity.isLiked ||
                                  feedback.some(
                                    (item) =>
                                      item.id === activity.id && item.isLiked
                                  )
                                }
                                style={{
                                  pointerEvents:
                                    activity.isLiked ||
                                    feedback.some(
                                      (item) =>
                                        item.id === activity.id && item.isLiked
                                    )
                                      ? "none"
                                      : "auto",
                                }}
                              >
                                <Icon icon="Thumbs-Up" size={22} />
                              </Button>
                            </div>
                          </Tooltip>
                        )}
                        {activity.isLiked ||
                        feedback.some(
                          (item) => item.id === activity.id && item.isLiked
                        ) ? null : (
                          <Tooltip text="Dislike">
                            <div>
                              <Button
                                variant="link"
                                className="p-0 h-auto border-0"
                                size="sm"
                                onClick={() => handleNegativeFeedback(activity,index)}
                                disabled={
                                  activity.isDisLiked ||
                                  feedback.some(
                                    (item) =>
                                      item.id === activity.id && item.isDisLiked
                                  )
                                }
                                style={{
                                  pointerEvents:
                                    activity.isDisLiked ||
                                    feedback.some(
                                      (item) =>
                                        item.id === activity.id &&
                                        item.isDisLiked
                                    )
                                      ? "none"
                                      : "auto",
                                }}
                              >
                                <Icon icon="Thumbs-Down" size={22} />
                              </Button>
                            </div>
                          </Tooltip>
                        )}
                        <Tooltip text="Add additional feedback">
                          <Button
                            variant="link"
                            className="p-0 h-auto text-center pd-2"
                            size="sm"
                            disabled={(activity.messageFeedback && activity.messageFeedback?.userFeedBack !== "" )|| (
                              feedback.length ? feedback?.some(
                                (item) =>
                                  item?.id === activity.id && item.feedBack
                              ):false
                            )
                            }
                            onClick={() => handleOpenFeedbackModal(activity)}
                          >
                            <Icon
                              icon="edit"
                              size={18}
                              style={{ marginBottom: "2px", marginLeft: "2px" }}
                            />
                          </Button>
                        </Tooltip>
                        <Tooltip text="Copy">
                          <Button
                            variant="link"
                            className="p-0 h-auto text-center pd-2"
                            size="sm"
                            onClick={()=>handleCopy(activity.text)}
                          >
                            <Icon
                              icon="copy"
                              size={16}
                              style={{ marginBottom: "2px", marginLeft: "2px" }}
                            />
                          </Button>
                        </Tooltip>
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
            {activity?.from?.role === "bot" && activity?.value?.suggestions.length > 0 ?
             (<ChatBotSuggestions suggestions = {activity.value.suggestions}/>) 
             : null}
          </>
        );
      })}
      <div ref={activityEL}></div>
    </div>
  );
};
