import axios from "axios";
import { auth_routes } from "utilities/apiRoutes";
import { useDispatch, useSelector } from "react-redux";
import { setResetUserInfo, setUpdateTokens } from "store/slice/userInfoSlice";
import { setModalOpen } from "store/slice/modalSlice";
const useRefreshToken = () => {
  const dispatch = useDispatch();
  const refreshToken = useSelector((state) => state.userInfo.cRefreshToken);
  const refreshAccessToken = async () => {
    let token;
    try {
      const response = await axios.post(
        auth_routes.refreshToken,
        {},
        {
          headers: {
            "c-refresh-token": refreshToken,
          },
        }
      );

      dispatch(
        setUpdateTokens({
          accessToken: response.data.accessToken,
          cIdToken: response.data.idToken,
          cRefreshToken: refreshToken,
        })
      );
      token = response.data.accessToken;
    } catch (error) {
      dispatch(setResetUserInfo());
      dispatch(setModalOpen({ name: "" }));
    }
    return token;
  };

  const accessTokenInterceptor = axios.create();
  accessTokenInterceptor.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const token = await refreshAccessToken();
        originalRequest.headers["Authorization"] = `Bearer ${token}`;
        return accessTokenInterceptor(originalRequest);
      }
      return Promise.reject(error);
    },
    null,
    { synchronous: true }
  );
  return {
    accessTokenInterceptor,
  };
};

export default useRefreshToken;
