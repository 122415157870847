import { generalFAQ } from 'assets/data/data'
import FaqAccordion from 'components/FaqAccordion'
import React from 'react'

export function GeneralFAQ() {
  return (
    <>
    <h2 className='faqs-heading'>General FAQs</h2>
    <FaqAccordion faqs={generalFAQ}/>
    </>
  )
};