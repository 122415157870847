import React, { useEffect } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import FormField from "../../../components/FormField";
import useLogin from "hooks/useLogin";
import { Spinner } from "react-bootstrap";
import useFacebookSSO from "hooks/useFacebookSSO";
import googleIcoon from "assets/svg/icon-google.svg";
import fbIcoon from "assets/svg/icon-facebook.svg";
import rega from "assets/svg/rega.svg"
import { useGoogleLogin } from "@react-oauth/google";
import logo from "assets/svg/ctc-logo.svg";
import insta from "assets/svg/insta.svg";
import linkd from "assets/svg/link.svg";
import AccountLayout from "components/AccountLayout";
import { Link } from "react-router-dom";
import { ctcInstagram, ctcLinkdIn, ctcWebLink, modalNames } from "assets/data/data";
import { useSelector } from "react-redux";
import useGoogleSSO from "hooks/useGoogleSSO";
import ForgotPasswordPopup from "../ForgotPasswordPopup";
import VerifiedModal from "../VerifiedModal";
import { trackLoginPageVisit } from "../../../utilities/mixPanel";
export const Login = (props) => {
  const { isVisible } = props;
  const { connectToGoogle } = useGoogleSSO();
  const modal = useSelector((state) => state.modal.name);
  const {
    formik,
    isLoading,
    rememberMe,
    rememberCredentials,
    handleRememberMe,
    handleOpenForgotPasswordModal,
  } = useLogin(isVisible);
  const { connectToFacebook } = useFacebookSSO();

  const handleGoogleLogin = () => {
    trackLoginPageVisit("Login Page Visit", { isLoggedIn: true });
    connectToGoogle()
  }

  const handleFacebookLogin = () => {
    trackLoginPageVisit("Login Page Visit", { isLoggedIn: true });
    connectToFacebook()
  }


  useEffect(() => {
    trackLoginPageVisit("Login Page Visit");

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        trackLoginPageVisit("Login Page Visit");
      } else {
        trackLoginPageVisit("Login Page Visit");
      }
    };

    window.addEventListener("beforeunload", () => {
      trackLoginPageVisit("Login Page Visit");
    });

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", () => { });
      trackLoginPageVisit("Login Page Visit");
    };
  }, []);
  return (
    <AccountLayout>
      <ForgotPasswordPopup isVisible={modalNames.forgotPassword === modal} />
      <VerifiedModal
        isVisible={modalNames.verified === modal}
        title="An email has been sent"
      />
      <div className="row mb-4">
        <div className="col text-center sub-heading-auth">
        <div className="p-2">
          <img className="mobile-icon" src={rega} alt="rega"/>
        </div>
          <h3 className="text-primary fw-bold mb-2 mt-2">Welcome back</h3>
          <p>Welcome back! Please enter your details.</p>
        </div>
      </div>
      <Form
        noValidate
        className="w-100 d-flex justify-content-center flex-column align-items-center"
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <Form.Group
          xs="12"
          as={Col}
          className="mb-3"
          controlId="validationCustom02"
        >
          <FormField
            label="Email"
            type="Email"
            placeholder="my@email.com"
            isInvalid={formik.errors.email}
            isValid={formik.touched.email && !formik.errors.email}
            {...formik.getFieldProps("email")}
            error={formik.errors.email}
          />
        </Form.Group>
        <Form.Group
          xs="12"
          as={Col}
          className="mb-3"
          controlId="validationCustom01"
        >
          <FormField
            label="Password"
            type="password"
            className="field-icon"
            placeholder="Password"
            isInvalid={formik.errors.password}
            {...formik.getFieldProps("password")}
            error={formik.errors.password}
          />
        </Form.Group>
        <Form.Group
          xs="12"
          as={Col}
          className="mb-3 d-flex justify-content-between align-items-center"
        >
          <Form.Check
            type="checkbox"
            label="Remember me"
            className="mb-3"
            id="rememberMe"
            value={rememberMe}
            checked={rememberCredentials}
            onChange={handleRememberMe}
          />
          <Link
            to="#"
            className="small text-gray-700"
            onClick={handleOpenForgotPasswordModal}
          >
            Forgot Password?
          </Link>
        </Form.Group>

        <Form.Group xs="12" as={Col} className="formModalRow mb-3">
          <Button
            type="submit"
            variant="primary"
            size="lg"
            className="w-100 button-padding"
          // onClick={formik.handleSubmit}
          >
            {isLoading ? (
              <Spinner animation="border" variant="light" />
            ) : (
              "Log in"
            )}
          </Button>
        </Form.Group>
      </Form>
      <Row className="mb-3">
        <div className="col d-flex align-items-center">
          <span className="line"></span>
          <span className="or mx-2">or</span>
          <span className="line"></span>
        </div>
      </Row>
      <Button
        onClick={() => handleGoogleLogin()}
        type="button"
        variant="white"
        className="btn btn-outline-primary w-100 mb-3 sso-btn"
      >
        <img
          src={googleIcoon}
          alt="google"
          className="icon-position-left width-px-20"
        />
        <span>Log in With Google</span>
      </Button>

      <Button
        type="button"
        variant="white"
        className="btn btn-outline-primary w-100 sso-btn mb-5"
        onClick={() => handleFacebookLogin()}
      >
        <img
          src={fbIcoon}
          alt="facebook"
          className="icon-position-left width-px-20"
          style={{ marginLeft: "18px" }}
        />
        <span>Log in With Facebook</span>
      </Button>

      <div className="text-center account-text">
        <div className="col mb-2">
          <p>
            Don't have an account? &nbsp;
            <Link
              to="/signup"
              className="text-decoration-underline text-primary fw-semibold"
            >
              Sign Up
            </Link>
        </p>
        </div>
      </div>
      <div className="d-flex gap-3 justify-content-center pt-2">
        <a href={ctcWebLink} target="_blank" rel="noopener noreferrer">
          <img src={logo} alt="logo" style={{width:"100px"}}/></a>
        <a href={ctcInstagram} target="_blank" rel="noopener noreferrer">
          <img src={insta} alt="instagram" style={{width:"25px"}}/></a>
        <a href={ctcLinkdIn} target="_blank" rel="noopener noreferrer">
          <img src={linkd} alt="linkdin" style={{width:"25px"}}/></a>
      </div>
      <div className="d-flex justify-content-center mt-px-10 mb-px-12">
        <a href='https://rega-files.s3.ca-central-1.amazonaws.com/REGATermsofUse2023-8-24.docx.pdf' className='text-primary' target="_blank" rel="noopener noreferrer" >
          <p className='text-primary fs-4'>
            Terms of Use
          </p>
        </a >
        <span className="ms-2 me-2"> | </span>
        <a href='https://rega-files.s3.ca-central-1.amazonaws.com/REGAPrivacyPolicy.pdf' target="_blank" rel="noopener noreferrer">
          <p className='text-primary fs-4 '>Privacy Policy</p>
        </a>
      </div>
    </AccountLayout>
  );
};
