import React from "react";
import { calculationQuestions, homeQuestions, marketQuestions } from "assets/data/data";
import { setConversation } from "store/slice/conversationSlice";
import { useDispatch, useSelector } from "react-redux";
import Icon from "components/IconMoon/Icon";
import { Col, Row } from "react-bootstrap";
export const ExampleQuestions = () => {
  const dispatch = useDispatch();
  const { conversationText } = useSelector((state) => state.conversation);
  const handleQuestionClick = (question) => {
    dispatch(
      setConversation({
        conversationText: question.description,
        fieldFocus: true,
      })
    );
  };
  return (
    <div className="flex-grow-1 container-fluid overflow-auto">
      <Row >
        <Col xs="12" md="4" className="card-container">
        <div >
            <Icon icon="home" size={22} />
              <h3>Homes for Sale</h3>
            </div>
        {homeQuestions.map((question) => (
            <div
              className="link example-prompt"
              key={question.id} onClick={() => handleQuestionClick(question)}
            >
              <p>{question.description}</p>
            </div>
  
        ))}</Col>
        <Col xs="12" md="4" className="card-container">
        <div>
        <Icon icon="map-marked-alt" size={22} />
              <h3>Understand the Market</h3>
            </div>
        {marketQuestions.map((question) => (
            <div
              className="link example-prompt"
              key={question.id} onClick={() => handleQuestionClick(question)}
            >
              <p>{question.description}</p>
            </div>
        ))}</Col>
        <Col xs="12" md="4" className="card-container">
        <div>
        <Icon icon="calculator" size={18} />
              <h3>Perform Calculations</h3>
            </div>
        {calculationQuestions.map((question) => (
            <div
              className="link example-prompt"
              key={question.id} onClick={() => handleQuestionClick(question)}
            >
              <p>{question.description}</p>
            </div>
        ))}</Col>
      </Row>
    </div>
  );
};
