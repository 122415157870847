import { useEffect } from "react";
import { setModalOpen } from "store/slice/modalSlice";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setHeaderTitle } from "store/slice/headerSlice";
import { setConversation, setOnGoingConversationId } from "store/slice/conversationSlice";
import { setRemoveSelectedConversation } from "store/slice/saveConversationsSlice";
import { trackNewConversationByUser } from "../utilities/mixPanel";
import { routes } from "routes/Routes";
const useHeader = (disconnect) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const title = useSelector((state) => state.header.title);
  const accessToken = useSelector((state) => state.userInfo.accessToken);
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userInfo);
  const { selectedConversation } = useSelector(
    (state) => state.savedConversations
  );
  useEffect(() => {
    if (selectedConversation !== null) return;
    if (pathname !== "/") return;
    if (userInfo.firstName) {
      dispatch(setHeaderTitle(`Welcome Back, ${userInfo.firstName}`));
    } else {
      dispatch(setHeaderTitle());
    }
  }, [userInfo.firstName, dispatch, selectedConversation, pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleNewChat = () => {
    //disconnect();
    dispatch(setOnGoingConversationId(""))
    navigate(routes.main)
    dispatch(setRemoveSelectedConversation());
    dispatch(
      setConversation({
        fieldFocus: true,
        conversationText: "",
      })
    );
    if (userInfo.firstName) {
      dispatch(setHeaderTitle(`Welcome Back, ${userInfo.firstName}`));
      navigate(routes.main);
      trackNewConversationByUser(userInfo.email)

    } else {
      dispatch(setHeaderTitle());
      navigate(routes.main);
    }
  };

  return {
    accessToken,
    dispatch,
    setModalOpen,
    handleNewChat,
    title,
  };
};

export default useHeader;
