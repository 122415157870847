import React, { useRef } from 'react'
import { Accordion, } from 'react-bootstrap';
import "./faq-acordion.scss";
export function FaqAccordion(props){
  const faqEndRef = useRef(null);
  const scrollToBottom = (index) => {
    if(props?.faqs.length === index+1) {
      setTimeout(()=>{
        faqEndRef.current?.scrollIntoView({ bottom: 0, behavior: "smooth" })
      },200)
    }
  }
  return (
    <>
    <div className='accordion-margin'>
    <Accordion >
   {props?.faqs?.map((item, index) => (
    <Accordion.Item onClick={()=>scrollToBottom(index)} eventKey={item.id}>
    <Accordion.Header>{item.title}</Accordion.Header>
    <Accordion.Body >{item.description}</Accordion.Body>
  </Accordion.Item>
  ))}
 </Accordion>
 <div style={{height: "50px"}} ref={faqEndRef}></div>
    </div>
    </>
  )
}


