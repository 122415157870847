import React, { useEffect } from "react";
import { Alert as CustomAlert } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import "./alert.scss";
import { setAlert } from "store/slice/alertSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faCheck,
  faExclamation,
} from "@fortawesome/free-solid-svg-icons";

const alertTypes = {
  success: {
    backgroundColor: alert.lightTheme ? "#A3EED6" : "#F0F3F5",
    color: alert.lightTheme ? "#4F5051" : "#1AD598",
    icon: faCheck,
    iconBackgroundColor: "#1AD598",
  },
  error: {
    backgroundColor: alert.lightTheme ? "#F7B0B1" : "#F0F3F5",
    color: alert.lightTheme ? "#4F5051" : "#EA3A3D",
    icon: faClose,
    iconBackgroundColor: "#EA3A3D",
  },
  warning: {
    backgroundColor: alert.lightTheme ? "#FDE3BD" : "#F0F3F5",
    color: alert.lightTheme ? "#4F5051" : "#F9B959",
    icon: faExclamation,
    iconBackgroundColor: "#F9B959",
  },
  info: {
    backgroundColor: alert.lightTheme ? "#99D3FF" : "#F0F3F5",
    color: alert.lightTheme ? "#4F5051" : "#0090FF",
    icon: faExclamation,
    iconBackgroundColor: "#0090FF",
  },
};

export const Alert = () => {
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        setAlert({
          title: "",
          lightTheme: true,
          type: "error",
        })
      );
    }, 5000);
    return () => clearTimeout(timer);
  }, [alert]);
  return (
    <>
      {alert.title ? (
        <CustomAlert
          variant="warning"
          className="container__alert   d-flex align-items-center justify-content-between "
          style={{
            backgroundColor: alertTypes[alert.type].backgroundColor,
          }}
        >
          <div className=" d-flex align-items-center justify-content-between">
            <div
              className="icon-type rounded-circle d-flex align-items-center justify-content-center"
              style={{
                backgroundColor: alertTypes[alert.type].iconBackgroundColor,
              }}
            >
              <FontAwesomeIcon
                icon={alertTypes[alert.type].icon}
                size="lg"
                className="text-white"
              />
            </div>
            <p style={{ color: alertTypes[alert.type].color }}>{alert.title}</p>
          </div>
          <FontAwesomeIcon
            icon={faClose}
            size="lg"
            className=" p-2"
            inverse
            flip="horizontal"
            style={{
              color: "#4F5051",
            }}
            onClick={() => dispatch(setAlert({ title: "" }))}
          />
        </CustomAlert>
      ) : null}
    </>
  );
};
