import { useState, useEffect, useRef } from "react";
import { reva_message_base_url } from "utilities/apiRoutes";
import { modalNames } from "assets/data/data";
import { useSelector, useDispatch } from "react-redux";
import { setModalOpen } from "store/slice/modalSlice";
import useRefreshToken from "./useRefreshToken";
import { setAlert } from "store/slice/alertSlice";
import { trackUpVotesOnMessage, trackDownVotesOnMessage } from "utilities/mixPanel";
import { selectedResponseQuestion } from "utilities/commonMethod";

const useConversation = (activities) => {
  const { accessTokenInterceptor } = useRefreshToken();
  const activityEL = useRef(null);
  const [feedback, setFeedback] = useState([]);
  const [feedbackData, setFeedbackData] = useState({});
  const [userLastMessage, setUserLastMessage ] = useState("");
  const openNegativeFeedbackModal = useSelector((state) => state.modal.name);
  const openPositiveFeedbackModal = useSelector((state) => state.modal.name);
  const { accessToken, email } = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    activityEL?.current?.scrollIntoView({ behavior: "smooth" });
  }, [activities]);
  const handlePositiveFeedback = async (activity,index) => {
    setFeedback([...feedback, { id: activity.id, isLiked: true }]);
    try {
      trackUpVotesOnMessage(email, activity.id.split("|")[0], activity.id)
      await accessTokenInterceptor.patch(
        `${reva_message_base_url}`,
        {
          id: activity.id,
          conversationId: activity.id.split("|")[0],
          isLiked: "true",
          feedbackQuestion: selectedResponseQuestion(index,activities)
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleNegativeFeedback = async (activity,index) => {
    setFeedback([...feedback, { id: activity.id, isDisLiked: true }]);
    try {
      trackDownVotesOnMessage(email, activity.id.split("|")[0], activity.id)
      await accessTokenInterceptor.patch(
        `${reva_message_base_url}`,
        {
          id: activity.id,
          conversationId: activity.id.split("|")[0],
          isDisLiked: "true",
          feedbackQuestion: selectedResponseQuestion(index,activities)

        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  }
  const handleCopy = (text) => {
    try {
      navigator.clipboard.writeText(text);
      dispatch(
        setAlert({
          type: "success",
          title: "Successfully Copied.",
        })
      );
    } catch (error) {
      console.log(error)
    }
  }
  const setReviewFeedback = (feedBackReview) => {
    setFeedback([...feedback, feedBackReview]);
  }
  const handleOpenFeedbackModal = (activity) => {
    const activityWithFeedback = {
      id: activity.id,
      conversationId: activity.id.split("|")[0],
      isDisliked: true,
      feedBackFunc: setReviewFeedback,
    };
    setFeedbackData(activityWithFeedback);
    if (feedback.some(
      (item) =>
        item?.id === activity.id && (item.isDisLiked)
    ) || activity?.isDisLiked) {
      dispatch(
        setModalOpen({
          name: modalNames.conversationNegativeFeedback,
          message: activityWithFeedback,
        })
      );
    } else if (feedback.some(
      (item) =>
        item?.id === activity.id && (item.isLiked)
    ) || activity?.isLiked) {
      dispatch(
        setModalOpen({
          name: modalNames.conversationPositiveFeedback,
          message: activityWithFeedback,
        })
      )
    }
    else {
      dispatch(
        setAlert({
          type: "error",
          title: "Please like or dislike the response before you provide additional feedback.",
        })
      );
    }
  };

  return {
    activityEL,
    feedback,
    isVisible:
      openNegativeFeedbackModal === modalNames.conversationNegativeFeedback,
    isPositiveFeedbackModal: openPositiveFeedbackModal === modalNames.conversationPositiveFeedback,
    handlePositiveFeedback,
    handleOpenFeedbackModal,
    feedbackData,
    handleNegativeFeedback,
    handleCopy,
    setFeedback,
  };
};

export default useConversation;
