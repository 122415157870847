import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    botMessage: [],
};

export const botMessageSlice = createSlice({
    name: "botMessage",
    initialState,
    reducers: {
        setBotMessage: (state, action) => {
            state.botMessage = action.payload;
        },
    },
});

export const {
    setBotMessage,
} = botMessageSlice.actions;

export default botMessageSlice.reducer;
