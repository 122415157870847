import AccountLayout from 'components/AccountLayout'
import FormField from 'components/FormField'
import PasswordValidationBullets from 'components/PasswordValidationBullets'
import useUpdatePassword from 'hooks/useUpdatePassword'
import React from 'react'
import { Button, Col, Form, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { routes } from 'routes/Routes'

export function ForgotPassword() {
  const {
    formik,
    isLoading,
    displayPasswordValidationBullets,
    setDisplayPasswordValidationBullets,
  } = useUpdatePassword();
  return (
    <>
    <AccountLayout>
      <div className="row mb-4">
        <div className="col text-center sub-heading-auth sub-heading-auth">
          <h3 className="text-primary  fw-bold mb-2 ">Update your password</h3>
          <p >Please update your credentials to sign in</p>
        </div>
      </div>
      <Form
        noValidate
        className="w-100 d-flex justify-content-center flex-column align-items-center"
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <Form.Group
          xs="12"
          as={Col}
          className="mb-3"
          controlId="validationCustom01"
        >
          <FormField
            label="New Password"
            type="password"
            className="field-icon"
            placeholder="Password"
            isInvalid={formik.errors.newPassword}
            onFocus={() => setDisplayPasswordValidationBullets(true)}
            isValid={formik.touched.newPassword && !formik.errors.newPassword}
            {...formik.getFieldProps("newPassword")}
            error={formik.errors.newPassword}
          />
          {displayPasswordValidationBullets ? (
              <PasswordValidationBullets password={formik.values.newPassword} />
            ) : null}
        </Form.Group>
        <Form.Group
          xs="12"
          as={Col}
          className="mb-3"
          controlId="validationCustom01"
        >
          <FormField
            label="Confirm New Password"
            type="password"
            className="field-icon"
            placeholder="Password"
            onFocus={() => setDisplayPasswordValidationBullets(true)}
              isInvalid={formik.errors.confirmNewPassword}
              isValid={formik.touched.confirmNewPassword && !formik.errors.confirmNewPassword}
              {...formik.getFieldProps("confirmNewPassword")}
              error={formik.errors.confirmNewPassword}
          />
          
        </Form.Group>

        <Form.Group xs="12" as={Col} className="formModalRow mb-3">
          <Button
            type="submit"
            variant="primary"
            size="lg"
            className="w-100 mb-5 pt-px button-padding"
            onClick={formik.handleSubmit}
          >
            {isLoading ? (
              <Spinner animation="border" variant="light" />
            ) : (
              "Change Password"
            )}
            
          </Button>
        </Form.Group>
      </Form>

      <div className="text-center account-text">
        <div className="col">
          <p>
          Already have an account? &nbsp;
            <Link
              to={routes.login}
              className="text-decoration-underline text-primary fw-semibold"
            >
              Log In
            </Link>
          </p>
        </div>
      </div>
    </AccountLayout>
    </>
  )
}

