import { customizeResponseMaxCharAllowed } from 'assets/data/data';
import Modal from 'components/Modal'
import TextAreaCharCount from 'components/TextAreaCharCount';
import useCustomizeResponse from 'hooks/useCustomizeResponse'
import React from 'react'
import { Form, Spinner } from "react-bootstrap";

export const CustomizeResponseModal = ({isVisible}) => {
    const {formik, isLoading, enableResponseForChats, handleChange ,handleUnsaveResponse} = useCustomizeResponse();
  return (
    <>
    <Modal size={"lg"} show={isVisible} title="Customize Response">
    <Form
        className="container__customizeResponse mt-2 "
        onSubmit={formik.handleSubmit}
      >
     <p className='mb-3'>Tell REGA about yourself and preferences to get personalized answers, share as much as you'd like. </p>
     <textarea
      className="form-control w-100"
      id="customizeResponseDescription"
      rows="7"
      readOnly={!enableResponseForChats}
      maxLength={customizeResponseMaxCharAllowed}
      {...formik.getFieldProps("customizeResponseDescription")}
      placeholder="Some inspirations:&#10;&#8226; What motivated you to seek real estate information from REGA? Was it lifestyle changes, job relocation, investment goals, relocating, downsizing, or upgrading? &#10;&#8226; Do you prefer a house or a condo?&#10;&#8226; What locations or neighborhoods are you interested in? &#10;&#8226; What is your budget? &#10;&#8226; How many beds/baths? &#10;&#8226;Are there any desired features or specific requirements?"></textarea>
      <div className="d-flex justify-content-between">
      <TextAreaCharCount
              maxChar={customizeResponseMaxCharAllowed}
              currentCharCount={formik.values?.customizeResponseDescription?.length}
            />
            <div>
              {formik.touched.customizeResponseDescription && formik.errors.customizeResponseDescription ? (
                <Form.Control.Feedback className="d-block" type="invalid">
                  {formik.errors.customizeResponseDescription}
                </Form.Control.Feedback>
              ) : null}
            </div>
          </div>
     <div className='d-flex flex-column flex-lg-row gap-3 gap-sm-0 justify-content-sm-between align-items-center'>
     <div class="form-check form-switch">
     <label class="form-check-label" >Enable for new chats</label>
        <input class="form-check-input"
        checked={enableResponseForChats}
        onChange={handleChange}
        type="checkbox"
        id="flexSwitchCheckDefault" />
     </div>
     <div className='d-flex align-items-center'>
        <button className="btn text-primary border-color-none me-5 ps-0 password-btn"
        type='button'
        onClick={handleUnsaveResponse}>Cancel</button>
        <button className="btn btn-primary btn-modal">
           {isLoading ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    "Save"
                  )}
        </button>
     </div>
     </div>
     </Form>
    </Modal>
    </>
  )
}
