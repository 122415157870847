import React from "react";
import Modal from "components/Modal";
import { Row, Col, Button, Form, Spinner } from "react-bootstrap";
import FormField from "components/FormField";
import { useResetPassword } from "hooks/useResetPassword";
import PasswordValidationBullets from "components/PasswordValidationBullets";
export const ResetPasswordModal = ({ isVisible }) => {
  const {
    displayPasswordValidationBullets,
    setDisplayPasswordValidationBullets,
    isLoading,
    formik,
  } = useResetPassword(isVisible);
  return (
    <div>
      <Form
        noValidate
        className="w-100 d-flex justify-content-center align-items-center flex-column"
        style={{ marginTop: "12px" }}
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <Row>
          <Form.Group
            className="mb-3"
            as={Col}
            xs="12"
            controlId="validationCustom01"
          >
            <FormField
              label="Old password"
              type="password"
              placeholder="Enter Password"
              isInvalid={formik.errors.oldPassword}
              isValid={formik.touched.oldPassword && !formik.errors.oldPassword}
              error={formik.errors.oldPassword}
              {...formik.getFieldProps("oldPassword")}
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            as={Col}
            xs="12"
            controlId="validationCustom01"
          >
            <FormField
              label="New password"
              type="password"
              placeholder="Enter Password"
              isInvalid={formik.errors.newPassword}
              onFocus={() => setDisplayPasswordValidationBullets(true)}
              isValid={formik.touched.newPassword && !formik.errors.newPassword}
              error={formik.errors.newPassword}
              {...formik.getFieldProps("newPassword")}
            />

            {displayPasswordValidationBullets ? (
              <PasswordValidationBullets password={formik.values.newPassword} />
            ) : null}
          </Form.Group>
          <Form.Group
            className="mb-3"
            as={Col}
            xs="12"
            controlId="validationCustom01"
          >
            <FormField
              label="Confirm new password"
              type="password"
              placeholder="Enter Password"
              isInvalid={formik.errors.confirmNewPassword}
              isValid={
                formik.touched.confirmNewPassword &&
                !formik.errors.confirmNewPassword
              }
              error={formik.errors.confirmNewPassword}
              {...formik.getFieldProps("confirmNewPassword")}
            />
          </Form.Group>
        </Row>

        <div className="w-100 mt-4 text-center">
          <Button variant="primary"
                  type="submit"
                  className=" btn-modal" >
            {isLoading ? (
              <Spinner animation="border" variant="light" />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </Form>{" "}
    </div>
  );
};
