import { renterFaq } from 'assets/data/data'
import FaqAccordion from 'components/FaqAccordion'
import React from 'react'

export function RentalFAQ() {
  return (
    <>
    <h2 className='ms-2 mt-4 faqs-heading'>Renter FAQs</h2>
    <FaqAccordion faqs={renterFaq}/>
    </>
  )
}