//* these are the backend api routes for the application

let base_url;
if (window.location.origin === "https://reva-sa.cityscapetechnology.com") {
  base_url = "https://reva-sa-prod-api.cityscapetechnology.com";
} else if (window.location.origin === "https://rega.ai") {
  base_url = "https://reva-sa-prod-api.cityscapetechnology.com";
} else if (
  window.location.origin === "https://reva-sa-staging.cityscapetechnology.com"
) {
  base_url = "https://reva-sa-stage-api.cityscapetechnology.com";
} else if (
  window.location.origin === "https://pre-prod-rega.cityscapetechnology.com"
) {
  base_url = "https://pre-prod-rega-api.cityscapetechnology.com";
}
else {
  base_url = "https://pre-prod-rega-api.cityscapetechnology.com";
}

const reva_base_url = `${base_url}/reva`;

export const reva_conversation_base_url = `${reva_base_url}/conversations`;
export const reva_message_base_url = `${reva_base_url}/messages`;
export const reva_text_to_speech = `${reva_base_url}/messages/text-to-voice`
export const reva_prompts = `${reva_base_url}/prompts`
export const reva_delete_user = `${reva_base_url}/users`

const auth_base_url = `${reva_base_url}/auth`;

export const auth_routes = {
  signup: `${auth_base_url}/sign-up`,
  signIn: `${auth_base_url}/sign-in`,
  verificationCode: `${auth_base_url}/verify-user`,
  resendVerificationCode: `${auth_base_url}/resend-verification-code`,
  forgotPassword: `${auth_base_url}/forgot-password`,
  confirmForgotPassword: `${auth_base_url}/confirm-forgot-password`,
  changePassword: `${auth_base_url}/change-password`,
  refreshToken: `${auth_base_url}/refresh-token`,
};

// export const rexplore_base_ul = `${base_url}/rexplore-management`;

const user_base_url = `${reva_base_url}/users`;

export const user_routes = {
  getUserAttributes: `${user_base_url}/user-attributes`,
  // getRecentSearch: `${user_base_url}/users`,
  // postRecentSearch: `${user_base_url}/users`,
};

const feedback_base_url = `${reva_base_url}/feedback`;
export const feedback_routes = {
  bugReport: `${feedback_base_url}/report-bug`,
  bugCategory: `${feedback_base_url}/bug-categories`,
  generalFeedback: `${feedback_base_url}/general-feedback`,
  featureRequest: `${feedback_base_url}/feature-request`,
};
