import mixpanel from "mixpanel-browser";

// track number of times a user has logged in
export const trackSignUp = (email) => {
  mixpanel.track("Sign Up", { email });
};

export const trackingDidNotSignUp = (email) => {
  mixpanel.track("Not Sign Up", { email });
};
export const trackLogin = ({
  email,
  userID,
  firstName,
  lastName,
  phoneNumber,
}) => {
  mixpanel.identify(email);
  mixpanel.track("Login", {
    email,
    userID,
    firstName,
    lastName,
    phoneNumber,
  });
};

export const trackPageVisitStart = (pageName, email = "") => {
  if (email) {
    mixpanel.identify(email);
  }
  mixpanel.track(pageName);
};

export const trackSignUpPageVisit = (pageName, isSignUp = false) => {
  if (isSignUp) {
    mixpanel.track(pageName, isSignUp);
  } else {
    mixpanel.track(pageName, { isSignUp: false });
  }
};

export const trackLoginPageVisit = (pageName, isLoggedIn = false) => {
  if (isLoggedIn) {
    mixpanel.track(pageName, isLoggedIn);
  } else {
    mixpanel.track(pageName, { isLoggedIn: false });
  }
};
export function trackPageVisitDuration(pageName) {
  mixpanel.track(pageName);
}

export const trackBotConversation = (email, conversationId, title) => {
  mixpanel.identify(email);
  mixpanel.track("Bot Conversation", {
    conversationId,
    title,
  });
};


export const trackUpVotesOnMessage = (email, conversationId, messageId) => {
  mixpanel.identify(email);
  mixpanel.track("Up Votes On Message", {
    conversationId,
    messageId,
  });
};
export const trackDownVotesOnMessage = (email, conversationId, messageId) => {
  mixpanel.identify(email);
  mixpanel.track("Down Votes On Message", {
    conversationId,
    messageId,
  });
};

export const trackFeedback = (email) => {
  mixpanel.identify(email);
  mixpanel.track("Feedback", {
    email
  });
};

export const setUserTracking = (email, firstName, lastName) => {
  mixpanel.identify(email);
  mixpanel.people.set({
    $name: `${firstName} ${lastName}`,
    $email: email,
    firstName: firstName,
    lastName: lastName
  });
  mixpanel.people.increment('$increments', 1);
}

export const trackUserLoginFromGoogle = (email) => {
  mixpanel.identify(email);
  mixpanel.track("Google Login", {
    email
  });
};

export const trackUserLoginFromFacebook = (email) => {
  mixpanel.identify(email);
  mixpanel.track("Facebook Login", {
    email
  });
};

export const trackNewConversationByUser = (email) => {
  mixpanel.identify(email);
  mixpanel.track("New Conversation", {
    email
  });
}


export const trackQuestionsAskedByUser = (email, question) => {
  mixpanel.track("Question Asked", {
    question: question,
    email: email,
  });

} 