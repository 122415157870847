import Modal from 'components/Modal'
import useDeleteAccount from 'hooks/useDeleteAccount'
import React from 'react'
import { Spinner } from 'react-bootstrap';

export const DeleteAccount = ({isVisible}) => {
    const { handleDeleteAccount ,isLoading ,handleAccountSettingModal } = useDeleteAccount();

  return (
      <Modal size={"lg"} show={isVisible} title="Delete Account">
        <div>
          <p className='mt-2 mb-2 fs-6'>
            Are you sure you want to delete your account? 
          </p>
          <ul className='bullet-points ms-4'>
            <li>Your profile details, preferences, and settings will be removed.</li>
            <li>Your search history, conversations, and any other content you've shared will be deleted.</li>
            <li>All data will be permanently deleted 30 days after account deletion. Once deleted, your data will not be used in REGA AI models.</li>
            <li>Deleting your account is permanent and cannot be undone.</li>
          </ul>
          <div className="mt-px-25 d-flex justify-content-end gap-2">
          <button onClick={handleAccountSettingModal} className="btn  bg-white text-primary">
            Cancel
          </button>
          <button onClick={handleDeleteAccount} className="btn btn-primary">
            {isLoading ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    "Yes, Delete"
                  )}
          </button>
        </div>
        </div>
      </Modal>
  )
}
