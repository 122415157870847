import axios from "axios";
import awsExports from "../aws-exports";
import { chatbotKey } from "auth/Keys";
import { excludedWords } from "assets/data/data";
// ** check if an object is empty
export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

export const scrollToSection = (id) => {
  document
    .getElementById(id)
    .scrollIntoView({ behavior: "smooth", inline: "center" });
};
// ** get directLine token
export async function getDirectLineToken() {
  const { data } = await axios.post(
    "https://directline.botframework.com/v3/directline/conversations",
    {},
    {
      headers: {
        Authorization: `Bearer ${chatbotKey}`,
      },
    }
  );
  return data;
}
// ** reconnect to the original conversation
export async function reconnectToConversation(conversationId) {
  const { data } = await axios(
    `https://directline.botframework.com/v3/directline/conversations/${conversationId}`,
    {
      headers: {
        Authorization: `Bearer ${chatbotKey}`,
      },
    }
  );
  return data;
}
// ** returns the text after applying lowercase conjunctions , articles and prepositions
export const capitalizationRule = (str) => {
  return str
    ?.split(' ')
    ?.map((word, index) => {
      if (index === 0 || !excludedWords.includes(word.toLowerCase())) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      } else {
        return word;
      }
    })
    .join(' ');
}
// ** return string with first letter capital 
export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
// ** returns the question for selected bot response
export const selectedResponseQuestion = (response,activities) => {
    const selectedMessagesArray =[]
    
    for (let i=0 ; i < activities.length ; i++){
       if( i !== response){
        selectedMessagesArray.push(activities[i])
       }
       else {
        break;
       }
    }
    const selectedUserMessages = selectedMessagesArray.filter(
      (activity) => activity?.from?.role === "user"
      );
    return selectedUserMessages[selectedUserMessages.length - 1].text;
}
// ** returns aws-cognito configuration bases on environment
export const getAwsConfig = () => {
  const config = {
    dev: {
      ...awsExports,
      aws_project_region: "ca-central-1",
      aws_cognito_region: "ca-central-1",
      aws_user_pools_id: "ca-central-1_yyyBL0Zx3",
      aws_user_pools_web_client_id: "4k24l8u4jbvid5m1bgmd0o1k82",
      oauth: {
        ...awsExports.oauth,
        domain: "reva-sa-pre-prod.auth.ca-central-1.amazoncognito.com",
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
      },
    },
    staging: {
      ...awsExports,
      aws_user_pools_id: "eu-central-1_KUKQP2BIH",
      aws_user_pools_web_client_id: "1c1jcqcogt0mpgqn6mietb7op6",
      oauth: {
        ...awsExports.oauth,
        domain: "reva-sa-stag.auth.eu-central-1.amazoncognito.com",
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
      },
    },
    prod: {
      ...awsExports,
      aws_project_region: "ca-central-1",
      aws_cognito_region: "ca-central-1",
      aws_user_pools_id: "ca-central-1_dv1KJ3Gdk",
      aws_user_pools_web_client_id: "5qnlofi5u7f31p7k6e568955kr",
      oauth: {
        ...awsExports.oauth,
        domain: "reva-sa-prod.auth.ca-central-1.amazoncognito.com",
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
      },
    },
    pre_prod: {
      ...awsExports,
      aws_project_region: "ca-central-1",
      aws_cognito_region: "ca-central-1",
      aws_user_pools_id: "ca-central-1_yyyBL0Zx3",
      aws_user_pools_web_client_id: "4k24l8u4jbvid5m1bgmd0o1k82",
      oauth: {
        ...awsExports.oauth,
        domain: "reva-sa-pre-prod.auth.ca-central-1.amazoncognito.com",
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
      },
    },
  };

  if (window.location.origin === "https://reva-sa.cityscapetechnology.com") {
    //!  production environment
    return config.prod;
  } else if (window.location.origin === "https://rega.ai") {
    //!  production environment
    return config.prod;
  } else if (
    window.location.origin === "https://reva-sa-staging.cityscapetechnology.com"
  ) {
    //!  staging environment
    return config.staging;
  } else if (
    window.location.origin === "https://pre-prod-rega.cityscapetechnology.com"
  ) {
    //!  staging environment
    return config.pre_prod;
  }
   else {
    //!  development environment
    return config.dev;
  }
};
