import React from "react";
import "./siteFeedbackButton.scss";
import { modalNames } from "assets/data/data";
import { setModalOpen } from "store/slice/modalSlice";
import { useDispatch } from "react-redux";
export const SiteFeedbackButton = () => {
  const dispatch = useDispatch();
  const handleOpenFeedbackOptionsModal = () => {
    dispatch(setModalOpen({ name: modalNames.siteFeedbackOptions }));
  };
  return (
    <div
      className=" site-feedback-button "
      onClick={handleOpenFeedbackOptionsModal}
    >
      <p>Feedback</p>
    </div>
  );
};
