import React from 'react'
import  frame from "assets/svg/Frame.svg"
import "./splashScreen.scss"

export const SplashScreen = () => {
  return (
    <div className="w-100 h-100 bg-black d-flex justify-content-center align-items-center">
        <img src={frame} alt="logo" className="img-fluid" />
    </div>
  )
}
