import { useSelector } from "react-redux";
import useRefreshToken from "./useRefreshToken";
import { reva_text_to_speech } from "utilities/apiRoutes";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const useTextToSpeech = () => {
  const { accessTokenInterceptor } = useRefreshToken();
  const [audioArray, setAudioArray] = useState([]);
  const { accessToken } = useSelector((state) => state.userInfo);
  const { botMessage } = useSelector((state) => state.botMessage);
  const location = useLocation();

  const handleTextToSpeech = async () => {
    if (location.pathname === "/") {
      try {
        const response = await accessTokenInterceptor.post(
          `${reva_text_to_speech}`,
          {
            languageCode: "en-GB",
            name: "en-GB-Neural2-B",
            message: botMessage,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response) {
          setAudioArray(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  return { handleTextToSpeech, audioArray };
};

export default useTextToSpeech;
