import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useRefreshToken from "./useRefreshToken";
import { useSelector, useDispatch } from "react-redux";
import { setModalOpen } from "store/slice/modalSlice";
import { setAlert } from "store/slice/alertSlice";
import { feedback_routes } from "utilities/apiRoutes";
import { feedbackMaxCharAllowed } from "assets/data/data";
import { trackFeedback } from "utilities/mixPanel";

const useBugReportFeedbackModal = (isVisible) => {
  const { accessTokenInterceptor } = useRefreshToken();
  const { userID, accessToken, email } = useSelector((state) => state.userInfo);
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [attachmentError, setAttachmentError] = useState("");
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      category: "",
      description: "",
    },
    validationSchema: Yup.object({
      category: Yup.string(),
      description: Yup.string()
        .required("Required")
        .max(
          feedbackMaxCharAllowed,
          `Must be ${feedbackMaxCharAllowed} characters or less`
        ),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (attachmentError) return;
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append("category", values.category);
        formData.append("description", values.description);
        formData.append("userId", userID);

        for (let i = 0; i < attachments.length; i++) {
          formData.append("attachments", attachments[i]);
        }

        const response = await accessTokenInterceptor.post(
          feedback_routes.bugReport,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + accessToken,
            },
          }
        );
        if (response) {
          trackFeedback(email)
          dispatch(setAlert({ title: "Feedback sent successfully" }));
          dispatch(setModalOpen({ name: "" }));
          resetForm();
        }
      } catch (error) {
        dispatch(
          setAlert({ title: error.response.data.errorDetails, type: "error" })
        );
        resetForm();
        setAttachments([]);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleFileUpload = (files) => {
    setAttachments(files);
  };

  useEffect(() => {
    const getCategories = async () => {
      try {
        const { data } = await accessTokenInterceptor.get(
          feedback_routes.bugCategory,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        );
        if (data) {
          setCategories(data);
        }
      } catch (error) {
        dispatch(
          setAlert({ title: error.response.data.errorDetails, type: "error" })
        );
      }
    };
    if (isVisible) {
      formik.resetForm();
      setAttachments([]);
      setAttachmentError("");
      getCategories();
    }
  }, [isVisible]);
  return {
    categories,
    attachmentError,
    setAttachmentError,
    feedbackMaxCharAllowed,
    formik,
    isLoading,
    handleFileUpload,
  };
};

export default useBugReportFeedbackModal;
