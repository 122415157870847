import React from "react";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import useShowPassword from "../../hooks/useShowPassword";
export const FormField = (props) => {
  const { label, type } = props;
  const { showPassword, handleShowPassword } = useShowPassword();
  return (
    <>
      {label ? (
        <label className="form-label" htmlFor={type}>
          {label}
        </label>
      ) : null}
      <Form.Group className="d-flex position-relative flex-column">
        <Form.Control {...props} type={showPassword ? "text" : type} />
        {type === "password" ? (
          <span
            className="position-absolute end-0 mt-px-10 me-2"
            style={{ color: "#94A3B8" }}
          >
            <FontAwesomeIcon
              icon={showPassword ? faEye : faEyeSlash}
              size="sm"
              className=" field-icon-position-right cursor-pointer"
              onClick={handleShowPassword}
              flip="horizontal"
            />
          </span>
        ) : null}
        <Form.Control.Feedback type="invalid">
          {props.error}
        </Form.Control.Feedback>
        {props.isValid && props.value ? (
          <Form.Control.Feedback type="valid">
            Looks good!
          </Form.Control.Feedback>
        ) : null}
      </Form.Group>
    </>
  );
};
