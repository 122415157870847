import Modal from "components/Modal";
import React from "react";
import { setResetUserInfo } from "store/slice/userInfoSlice";
import { setModalOpen } from "store/slice/modalSlice";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
export const LogoutModal = ({ isVisible }) => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    Auth.signOut();
    dispatch(setResetUserInfo());
    dispatch(setModalOpen({ name: "" }));
     // Post a message to notify the native app that the user has logged out
    window.ReactNativeWebView.postMessage('userLoggedOut');
  };
  const handleClose = () => {
    dispatch(setModalOpen({ name: "" }));
  };
  return (
    <Modal title={"Confirm logout"} show={isVisible}>
      <p className="mt-2">Are you sure you want to logout?</p>
      <div
        className="d-flex justify-content-end gap-2 "
        style={{ marginTop: "1.5rem" }}
      >
        <button
          className="btn bg-white  text-primary btn-modal"
          onClick={handleClose}
        >
          Cancel
        </button>

        <button className="btn btn-primary btn-modal" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </Modal>
  );
};
