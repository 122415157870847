import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { reva_conversation_base_url } from "utilities/apiRoutes";
import {
  setSaveConversation,
  setRemoveConversationById,
  setSelectedConversation,
  setUpdateConversationName,
  setRemoveSelectedConversation,
} from "store/slice/saveConversationsSlice";
import { setHeaderTitle } from "store/slice/headerSlice";
import {
  setResetConversation,
  setOnGoingConversationId,
} from "store/slice/conversationSlice";
import useRefreshToken from "./useRefreshToken";
import { useLocation, useNavigate } from "react-router";
import { routes } from "routes/Routes";
import { setModalOpen } from "store/slice/modalSlice";

const useSavedConversation = () => {
  const { accessTokenInterceptor } = useRefreshToken();
  const [showEditConView, setShowEditConView] = useState(false);
  const savedConversations = useSelector(
    (state) => state.savedConversations.conversations
  );
  const { selectedConversation } = useSelector(
    (state) => state.savedConversations
  );
  const { onGoingConversationId } = useSelector((state) => state.conversation);
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  const { userID, accessToken } = useSelector((state) => state.userInfo);
  const conversationId = useSelector((state) => state.modal.message?.id);
  const index = useSelector((state) => state.modal.message?.index);
  const key = useSelector((state) => state.modal.message?.key);

  const navigate = useNavigate();
  const pathname = useLocation();
  useEffect(() => {
    if (pathname !== "/" && selectedConversation) {
      navigate(routes.main);
    }
  }, [selectedConversation]);
  useEffect(() => {
    const getSavedConversations = async () => {
      try {
        const { data } = await accessTokenInterceptor.get(
          `${reva_conversation_base_url}/users/${userID}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params:{
              groupByTimePeriod: true
            }
          }
        );
        dispatch(setSaveConversation(data));
      } catch (error) {
        console.log(error);
      }
    };
    getSavedConversations();
  }, [userID]);

  const handleSelectedConversation = (conversationId, name) => {
    dispatch(setHeaderTitle(name));
    dispatch(setSelectedConversation(conversationId));
    dispatch(setOnGoingConversationId(conversationId));
  };
  const handleEditConversationTitle = (id) => {
    setShowEditConView(id);
  };
  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleUpdateConversationTitle = async (id,index,key) => {

    const conversation =  Object.values(savedConversations).flat().find(record => record.conversationId === id);
   
    dispatch(
      setUpdateConversationName({
        id,
        name: title || conversation.name,
        index,
        key
      })
    );
    if (selectedConversation?.conversationId === id) {
      dispatch(setHeaderTitle(title || conversation.name));
    }
    setShowEditConView("");
    // this will update the conversation name in the database

    try {
      const { data } = await accessTokenInterceptor.patch(
        `${reva_conversation_base_url}`,
        {
          name: title,
          conversationId: id,
          userId: userID,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (data) {
        setTitle("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveConversationById = async (createNewConversation) => {
    if (
      selectedConversation?.conversationId === conversationId ||
      onGoingConversationId === conversationId
    ) {
      createNewConversation();

      dispatch(setResetConversation());
      dispatch(setRemoveSelectedConversation());
      dispatch(setModalOpen({ name: "" }));
      if (pathname !== "/") {
        dispatch(setHeaderTitle());
      }
    }
    dispatch(setRemoveConversationById({conversationId,key,index}));
    dispatch(setModalOpen({ name: "" }));
    try {
      await accessTokenInterceptor.delete(
        `${reva_conversation_base_url}/${conversationId}/users/${userID}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return {
    showEditConView,
    savedConversations,
    setTitle,
    handleChangeTitle,
    handleEditConversationTitle,
    handleUpdateConversationTitle,
    handleSelectedConversation,
    handleRemoveConversationById,
    setShowEditConView,
  };
};

export default useSavedConversation;
