import Icon from 'components/IconMoon/Icon'
import React from 'react'

export const ChatBotSuggestions = ({suggestions}) => {
    
  return (
    <div className="conversation response-bg">
      <div className="conversation-inner">
        <div className="d-flex">
          <div className="avatar logo">
            <>
              <div className="avatar-container ">
                <Icon icon="rega" size={24} />
              </div>
              </>
            </div>
              <div className="message ">
                <p className='mb-px-12'>Here are some suggestions for seeking further information like: </p>
                <ul>
                {suggestions.map((suggestion,index) => {
                    return ( <li >{suggestion}</li> )
                  })}
                </ul>
            </div>
            </div> 
        </div>
     </div>
  )
}
