import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { auth_routes } from "utilities/apiRoutes";
import { useSelector, useDispatch } from "react-redux";
import { setAlert } from "store/slice/alertSlice";
import { setModalOpen } from "store/slice/modalSlice";
import { setUserInfo  ,setUserChatHistory, setUserDataConsent, setUserMessagePreferences} from "store/slice/userInfoSlice";
import { modalNames } from "assets/data/data";
import { useNavigate, useSearchParams } from "react-router-dom";
import { routes } from "routes/Routes";
import {
  setRememberMe,
  setRemoveRememberMe,
} from "store/slice/rememberMeSlice";
import { setUserTracking, trackLogin, trackLoginPageVisit, trackSignUp, trackingDidNotSignUp } from "utilities/mixPanel";
import { setTheme } from "store/slice/themeSlice";
const useLogin = (isVisible) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const rememberMe = useSelector((state) => state.rememberMe);
  const [initialEmail] = useState(rememberMe.email);
  const [initialPassword] = useState(rememberMe.password);
  const [searchParams] = useSearchParams();
  const accessToken = useSelector((state) => state.userInfo.accessToken);
  const navigate = useNavigate();
  const checkLogin = () => {
    if (searchParams.get('email')) {
      const cIdToken = searchParams.get('cIdToken');
      const cRefreshToken = searchParams.get('cRefreshToken');
      const accessToken = searchParams.get('accessToken');
      const email = searchParams.get('email');
      const userID = searchParams.get('userID');
      const firstName = searchParams.get('firstName');
      const lastName = searchParams.get('lastName');
      const phoneNumber = searchParams.get('phoneNumber');
      dispatch(
        setUserInfo({
          cIdToken,
          cRefreshToken,
          accessToken,
          email,
          userID,
          firstName,
          lastName,
          phoneNumber,
        })
      );

      navigate(routes.main);
      dispatch(
        setAlert({
          type: "success",
          title: "Successfully logged in",
        })
      );
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  useEffect(() => {
    if (!searchParams.get("verificationCode")) return;
    let isMounted = true;
    const sendUserVerification = async () => {
      try {
        const response = await axios.post(`${auth_routes.verificationCode}`, {
          email: searchParams.get("email").replace(" ", "+"),
          code: searchParams.get("verificationCode"),
        });
        if (response) {
          trackSignUp(searchParams.get("email").replace(" ", "+"));
          dispatch(
            setAlert({
              type: "success",
              title: "Successfully verified. Please Login",
            })
          );
        }
      } catch (error) {
        trackingDidNotSignUp(searchParams.get("email").replace(" ", "+"));
        if (error) {
          dispatch(
            setAlert({
              type: "error",
              title: error.response?.data.errorDetails,
            })
          );
        }
      }
    };
    if (isMounted) sendUserVerification();
    return () => {
      isMounted = false;
    };
  }, [searchParams, dispatch]);

  const [rememberCredentials, setRememberCredentials] = useState(
    rememberMe.rememberMe
  );
  const formik = useFormik({
    initialValues: {
      email: initialEmail,
      password: initialPassword,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid Email").required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        const response = await axios.post(`${auth_routes.signIn}`, values);
        if (response) {
          trackLoginPageVisit("Login Page Visit", { isLoggedIn: true });
          navigate(routes.main);
          setUserTracking(response.data.userAttributes.email, response.data.userAttributes["custom:firstName"], response.data.userAttributes["custom:lastName"])
          dispatch(
            setUserInfo({
              accessToken: response.data.AccessToken,
              cRefreshToken: response.data.RefreshToken,
              cIdToken: response.data.IdToken,
              email: response.data.userAttributes.email,
              userID: response.data.userAttributes.sub,
              firstName: response.data.userAttributes["custom:firstName"] || "",
              lastName: response.data.userAttributes["custom:lastName"] || "",
              phoneNumber: response.data.userAttributes.phone_number || "",
              isMessagePreferences: response.data.userAttributes["custom:messagePreference"] || "",
            })
          );
          if(response.data.userAttributes["custom:isDarkMode"]){
            if(response.data.userAttributes["custom:isDarkMode"] === "true"){
              dispatch(setTheme("dark"))
            } else {
              dispatch(setTheme("light"))
            }
          }
          else {
            const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
            if(mediaQuery.matches) {
              dispatch(setTheme("dark"))
            }
            else {
              dispatch(setTheme("light"))
            }
          }
          dispatch(
            setUserDataConsent({
              isDataConsent:response.data.userAttributes["custom:isFirstLoggedIn"] || "",
            })
          );
          dispatch(
            setUserChatHistory({
              isChatHistory:response.data.userAttributes["custom:isChatHistory"] || "",
            })
          );
          dispatch(
            setUserMessagePreferences({
              isMessagePreferences: response.data.userAttributes["custom:messagePreference"] || "",
            })
          )
          if (rememberMe) {
            dispatch(
              setRememberMe({
                email: values.email,
                password: values.password,
                rememberMe: true,
              })
            );
          }

          setIsLoading(false);

          dispatch(
            setAlert({
              type: "success",
              title: "Successfully logged in",
            })
          );
          const trackingData = {
            email: response.data.userAttributes.email,
            userID: response.data.userAttributes.sub,
            firstName: response.data.userAttributes["custom:firstName"] || "",
            lastName: response.data.userAttributes["custom:lastName"] || "",
            phoneNumber: response.data.userAttributes.phone_number || "",
          };
          trackLogin(trackingData);
        }
        resetForm();
      } catch (error) {
        setIsLoading(false);
        if (rememberMe) {
          dispatch(
            setRememberMe({
              email: values.email,
              password: values.password,
              rememberMe: true,
            })
          );
        }
        if (error) {
          if (
            error?.response?.data.errorCode ===
            "1001_0001_0005_USER_NOT_CONFIRMED"
          ) {
            dispatch(
              setModalOpen({
                name: modalNames.enterVerificationCode,
                message: values.email,
              })
            );
          }

          dispatch(
            setAlert({
              type: "error",
              title: error.response?.data.errorDetails,
            })
          );
        }
      }
    },
  });
  useEffect(() => {
    if (accessToken) navigate(routes.main);
    formik.resetForm();
  }, [isVisible]);

  const handleOpenForgotPasswordModal = () => {
    dispatch(setModalOpen({ name: modalNames.forgotPassword }));
  };

  const handleRememberMe = (e) => {
    setRememberCredentials(!rememberCredentials);
    if (e.target.checked) {
      dispatch(
        setRememberMe({
          email: formik.values.email,
          password: formik.values.password,
          rememberMe: e.target.checked,
        })
      );
    } else {
      dispatch(setRemoveRememberMe());
    }
  };

  return {
    isLoading,
    formik,
    rememberMe,
    rememberCredentials,
    handleRememberMe,
    handleOpenForgotPasswordModal,
  };
};
export default useLogin;
