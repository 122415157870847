import { useEffect } from "react";

import { Auth } from "aws-amplify";
import { gapi } from "gapi-script";

import { GOOGLE_ID } from "auth/Keys";
const useGoogleSSO = () => {
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: GOOGLE_ID,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);

    return () => { };
  }, []);

  const connectToGoogle = async () => {
    await Auth.federatedSignIn({ provider: "Google" });
  };

  return { connectToGoogle };
};

export default useGoogleSSO;
