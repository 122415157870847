import React, { useEffect, useRef, useState } from 'react'

export const AudioPlayer = ({audioUrls,setIsPlaying}) => {
    const audioRef = useRef(null);
  const [currentAudioIndex, setCurrentAudioIndex] = useState(0);

  useEffect(() => {
    const audioElement = audioRef.current;

    const handleEnded = () => {
      // Play the next audio when the current audio ends
      setCurrentAudioIndex((prevIndex) => {
        if (prevIndex === audioUrls.length - 1) {
          // Last audio file, stop playback
          audioElement.pause();
          setIsPlaying(false);
          return prevIndex;
        }
        return prevIndex + 1;
      });
    };
    audioElement.addEventListener('ended', handleEnded);

    return () => {
      audioElement.removeEventListener('ended', handleEnded);
    };
  }, [audioUrls]);

  useEffect(() => {
    const audioElement = audioRef.current;
    audioElement.src = audioUrls[currentAudioIndex];
    if(audioUrls.length) {
      setIsPlaying(true)
      audioElement.play();
    }
    
  }, [audioUrls, currentAudioIndex]);

  return <audio ref={audioRef} autoPlay  style={{ display: 'none' }} />;

};