import { Routes, Route, Navigate } from "react-router-dom";
// Pages
import Home from "pages/Home";
import Typography from "./pages/typography.js";
import Signup from "./pages/UserManagement/Signup";
import Login from "./pages/UserManagement/Login";
import ForgotPassword from "pages/UserManagement/ForgotPassword";

// Assets
import "./../node_modules/react-bootstrap/dist/react-bootstrap.min";
import "./App.scss";

// Components
import Sidebar from "components/Sidebar";
import { SiteFeedbackOptionsModal } from "components/SiteFeedbackOptionsModal/SiteFeedbackOptionsModal.js";
// Fortawesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas, faSearch } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import {
  faGithubAlt,
  faGoogle,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import { Components } from "botframework-webchat-component";

import Spinner from "react-bootstrap/Spinner";

import FAQ from "pages/FAQ/";
import BuyersFAQ from "pages/FAQ/SubFaqs/BuyersFAQ";
import SellersFAQ from "pages/FAQ/SubFaqs/SellersFAQ";
import LegalFAQ from "pages/FAQ/SubFaqs/LegalFAQ";
import RecuirtmentFAQ from "pages/FAQ/SubFaqs/RecuirtmentFAQ";
import RentalFAQ from "pages/FAQ/SubFaqs/RentalFAQ";
import GeneralFAQ from "pages/FAQ/SubFaqs/GeneralFAQ";
import useApp from "hooks/useApp.js";
import PrivateRoutes from "routes/PrivateRoutes.js";
import SiteFeedbackButton from "components/SiteFeedbackButton";
import BugReportFeedbackModal from "components/BugReportFeedbackModal";
import GeneralFeedbackModal from "components/GeneralFeedbackModal";
import { modalNames } from "assets/data/data";
import { FeatureRequestFeedbackModal } from "components/FeatureRequestFeedbackModal/FeatureRequestFeedbackModal.js";
import mixpanel from "mixpanel-browser";
import { mixPanelKey } from "auth/Keys.js";
import DataConsentModal from "components/DataConsentModal";
import SplashScreen from "components/SplashScreen";
library.add(fas, far, faGithubAlt, faGoogle, faFacebook, faTwitter, faSearch);
mixpanel.init(mixPanelKey, { debug: true });
mixpanel.disable();
function App() {
  const {
    directLine,
    show,
    modal,
    setShow,
    store,
    userInfo,
    isAuthLoading,
    createNewConnection,
    showSplash,
  } = useApp();
  return (
    <>
      {showSplash && <SplashScreen />}
      <div className={`App h-100 d-flex flex-column w-100`}>
        <SiteFeedbackOptionsModal
          isVisible={modal === modalNames.siteFeedbackOptions}
        />
        <BugReportFeedbackModal
          isVisible={modal === modalNames.bugReportFeedback}
        />
        <GeneralFeedbackModal
          isVisible={modal === modalNames.generalFeedback}
        />
        <FeatureRequestFeedbackModal
          isVisible={modal === modalNames.featureRequest}
        />
        <DataConsentModal isVisible={modal === modalNames.dataConsent} />
        {!userInfo.accessToken ? null : (
          <>
            <Sidebar {...{ show, setShow, createNewConnection }} />
            <SiteFeedbackButton />
          </>
        )}
        <Routes>
          <Route
            element={
              isAuthLoading ? (
                <Spinner
                  animation="grow"
                  variant="primary"
                  className="m-auto"
                />
              ) : (
                <PrivateRoutes />
              )
            }
          >
            <Route
              exact
              path="/"
              element={
                !!directLine ? (
                  <Components.Composer directLine={directLine} store={store}>
                    <Home setShow={setShow} disconnect={createNewConnection} />
                  </Components.Composer>
                ) : (
                  <Spinner
                    animation="grow"
                    variant="primary"
                    className="m-auto"
                  />
                )
              }
            />
          </Route>
          <Route path="typography" element={<Typography />} />
          <Route path="signup" element={<Signup />} />
          <Route path="login" element={<Login />} />
          <Route path="updatePassword" element={<ForgotPassword />} />
          <Route
            element={
              isAuthLoading ? (
                <Spinner
                  animation="grow"
                  variant="primary"
                  className="m-auto"
                />
              ) : (
                <PrivateRoutes />
              )
            }
          >
            <Route path="faq" element={<FAQ setShow={setShow} />}>
              <Route path="buyer" element={<BuyersFAQ setShow={setShow} />} />
              <Route path="seller" element={<SellersFAQ setShow={setShow} />} />
              <Route
                path="land-lord"
                element={<LegalFAQ setShow={setShow} />}
              />
              <Route
                path="recruitment"
                element={<RecuirtmentFAQ setShow={setShow} />}
              />
              <Route path="renter" element={<RentalFAQ setShow={setShow} />} />
              <Route
                path="general"
                element={<GeneralFAQ setShow={setShow} />}
              />
              <Route path="*" element={<Navigate to="general" replace />} />
            </Route>
          </Route>
        </Routes>
      </div>
    </>
  );
}

export default App;
