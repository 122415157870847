import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import alertReducer from "./slice/alertSlice";
import modalReducer from "./slice/modalSlice";
import conversationReducer from "./slice/conversationSlice";
import saveConversationsSlice from "./slice/saveConversationsSlice";
import { headerSlice } from "./slice/headerSlice";

import userInfoReducer from "./slice/userInfoSlice";
import rememberMeSlice from "./slice/rememberMeSlice";
import themeSlice from "./slice/themeSlice";
import botMessageSlice from "./slice/bootMessageSlice";

const rootReducer = combineReducers({
  alert: alertReducer,
  modal: modalReducer,
  conversation: conversationReducer,
  savedConversations: saveConversationsSlice,
  header: headerSlice.reducer,
  userInfo: userInfoReducer,
  rememberMe: rememberMeSlice,
  theme: themeSlice,
  botMessage: botMessageSlice
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["userInfo", "rememberMe", "theme"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const createStore = () =>
  configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });
export const store = createStore();
