import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import FormField from "../../../components/FormField";
import { Spinner } from "react-bootstrap";
import useForgotPassword from "hooks/useForgotPassword";
import Modal from "components/Modal";
import { Link } from "react-router-dom";
export const ForgotPasswordPopup = (props) => {
  const { isVisible } = props;
  const { formik, isLoading } = useForgotPassword(isVisible);

  return (
    <Modal
      show={isVisible}
    >
     <div className="row mb-4">
        <div className="col text-center sub-heading-auth">
          <h3 className="text-primary fw-bold mb-2">Forgot Password</h3>
          <p>Please provide the required details.</p>
        </div>
      </div>
      <Form
        noValidate
        className="w-100 d-flex justify-content-center flex-column align-items-center"
        autoComplete="off"
      >
        <Row
          className="d-flex flex-column "
          style={{ maxWidth: "450px", width: "100%" }}
        >
          <Form.Group
            className="mb-3"
            as={Col}
            xs="12"
            controlId="validationCustom02"
          >
            <FormField
              label="Registered Email"
              type="Email"
              placeholder="my@email.com"
              isInvalid={formik.errors.email}
              isValid={formik.touched.email && !formik.errors.email}
              error={formik.errors.email}
              {...formik.getFieldProps("email")}
            />
          </Form.Group>
        </Row>

        <Row className="" style={{ maxWidth: "450px", width: "100%" }}>
          <Form.Group className=" formModalRow  mb-3" as={Col} xs="12">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              className="w-100 button-padding"
              onClick={formik.handleSubmit}
            >
              {isLoading ? (
                <Spinner animation="border" variant="light" />
              ) : (
                "Send Link"
              )}
            </Button>
          </Form.Group>
        </Row>
      </Form>
    </Modal>
  );
};
