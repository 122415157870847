import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Modal from "components/Modal";
import "./viewPromptsModal.scss";
import useViewPrompts from "../../hooks/useViewPrompts";


export const ViewPromptsModal = ({ isVisible }) => {

    const {
        searchTerm,
        setSearchTerm,
        filteredPrompts,
        handleSelectedPrompt,
        isLoading,
    } = useViewPrompts(isVisible);

    return (
        <Modal
            size={"xl"}
            show={isVisible}
            modalBodyClassName={"modal-bg-grey pt-0 pl-0 pr-0 pb-2 modal-content"}
            showHeaderIcon={false}
            showCloseButton={false}
        >
            <div className="search-bar">
                <input
                    className="search-bar-input"
                    type="text"
                    placeholder="Search for prompts"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
            </div>
            {isLoading ? (
                <Spinner
                    animation="grow"
                    variant="info"
                    size="md"
                    className="m-auto"
                />

            ) : (

                <div className="container__Prompts">
                    {filteredPrompts.length > 0 ? (
                        filteredPrompts.map(({ id, content }) => (
                            <div
                                className="view-prompts"
                                key={id}
                                onClick={() => handleSelectedPrompt(content)}
                            >
                                <p>{content}</p>
                            </div>
                        ))
                    ) : (
                        <p>No prompts found.</p>
                    )}
                </div>
            )}
        </Modal >
    );
};
