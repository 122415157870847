import { faqsType } from 'assets/data/data'
import Icon from 'components/IconMoon/Icon'
import React from 'react'
import { useNavigate } from 'react-router'
import { useState } from 'react'

export const FaqsTypes = () => {
    const navigate =  useNavigate();
    const [activeIndex, setActiveIndex] = useState(null);
    const navigateTo = (index,name) => {
        setActiveIndex(index);
        navigate(name);
    }
  return (
    <>
    <div className="container-fluid ">
    <ul className='row'>
        {faqsType.map((faq,index) => (
          <li className='col-6 col-md-6 col-lg-3' onClick={()=>navigateTo(index,faq.name)} key={faq.id}>
            <div
              className={`link example-prompts pt-4  h-auto w-100 text-center${
                faq.length ? "pe-none" : ""
              } ${activeIndex === index ? 'active' : ''}`}
            >
              <Icon icon={faq.icon} size={44} />
              <h4 className='mb-0 mt-2'>{faq.title}</h4>
              {/* <p>{faq.description}</p> */}
            </div>
          </li>
        ))}
      </ul>
    </div>
    </>
  )
}

