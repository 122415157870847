import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "store/slice/alertSlice";
import { setModalOpen } from "store/slice/modalSlice";
import { auth_routes } from "utilities/apiRoutes";
import useRefreshToken from "./useRefreshToken";
export const useResetPassword = (isVisible, email) => {
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const { accessTokenInterceptor } = useRefreshToken();
  const [
    displayPasswordValidationBullets,
    setDisplayPasswordValidationBullets,
  ] = useState(false);

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Current password is required"),

      newPassword: Yup.string()
        .required("New password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,~`.<>/?]+)(?=.{8,})/,
          " "
        )
        .notOneOf(
          [Yup.ref("oldPassword"), null],
          "Old password and new password cannot be same"
        ),
      confirmNewPassword: Yup.string()
        .required("Confirm your password")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    }),
    onSubmit: async (values, { resetForm }) => {
      console.log(
        "🚀 ~ file: useResetPassword.js:45 ~ onSubmit: ~ values:",
        values
      );

      setIsLoading(true);
      try {
        const response = await accessTokenInterceptor.post(
          `${auth_routes.changePassword}`,
          {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${userInfo.accessToken}`,
            },
          }
        );
        if (response) {
          dispatch(
            setModalOpen({
              name: "",
            })
          );
          dispatch(
            setAlert({
              type: "success",
              title: "Password successfully updated",
            })
          );
        }

        setIsLoading(false);
        resetForm();
      } catch (error) {
        setIsLoading(false);
        dispatch(
          setAlert({ type: "error", title: error.response?.data.errorDetails })
        );
        setDisplayPasswordValidationBullets(false);
        resetForm();
      }
    },
  });
  useEffect(() => {
    formik.resetForm();
    setDisplayPasswordValidationBullets(false);
  }, [isVisible]);

  return {
    isLoading,
    formik,
    displayPasswordValidationBullets,
    setDisplayPasswordValidationBullets,
  };
};
