import { landlordFAQ } from 'assets/data/data'
import FaqAccordion from 'components/FaqAccordion'
import React from 'react'

export function LegalFAQ() {
  return (
    <>
    <h2 className='ms-2 mt-4 faqs-heading'>Landlord FAQs</h2>
    <FaqAccordion faqs={landlordFAQ}/>
    </>
  )
};