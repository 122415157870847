import Modal from 'components/Modal'
import useDataConsent from 'hooks/useDataConsent';
import React from 'react';

export const DataConsentModal = ({ isVisible }) => {
  const { handleDataConsent } = useDataConsent();
  return (
    <>
      <Modal size="lg" show={isVisible} showHeaderIcon={false}
        showCloseButton={false}>
        <div className='consent-container m-2'>
          <p>
            We are committed to continuously improving REGA’s performance to better serve you. To achieve this,
            we may collect and analyze your chat logs, including questions, interactions,
            and general usage patterns. The collected data is used to enhance the chatbot's understanding, responsiveness,
            and accuracy, resulting in a better user experience.
          </p>
          <p className='mt-3'>
            Please note that if you decline to share your data, it may limit the chatbot's functionality and the quality of your experience. You can review or modify your consent settings at any time.
            For more information on our data practices,
            please refer to our <u className='text-primary'><a href='https://rega-files.s3.ca-central-1.amazonaws.com/REGAPrivacyPolicy.pdf' className='text-primary' target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a></u>.
          </p>
          <div className='consent-buttons d-flex mt-4'
          >
            <button
              onClick={() => handleDataConsent(false)}
              className="bg-transparent border-0 text-primary">
              Decline
            </button>
            <button className='btn btn-primary'
              onClick={() => handleDataConsent(true)}>
              Accept
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}
