import FeedbackFileAttachment from "components/FeedbackFileAttachment";
import React from "react";
import { Form, Spinner } from "react-bootstrap";
import Modal from "components/Modal";
import useFeatureRequestFeedbackModal from "hooks/useFeatureRequestFeedbackModal";
import TextAreaCharCount from "components/TextAreaCharCount";

export const FeatureRequestFeedbackModal = ({ isVisible }) => {
  const {
    formik,
    attachmentError,
    setAttachmentError,
    feedbackMaxCharAllowed,
    isLoading,
    handleFileUpload,
  } = useFeatureRequestFeedbackModal(isVisible);
  return (
    <Modal title="Feature Request" show={isVisible}>
      <Form className=" mt-2 " onSubmit={formik.handleSubmit}>
        {/* text area */}
        <div className="mb-3">
          <textarea
            className="form-control"
            id="bugDescription"
            rows="7"
            maxLength={feedbackMaxCharAllowed}
            placeholder="Briefly describe to us what do you want in the next version of REGA..."
            {...formik.getFieldProps("description")}
          />
          <div className="d-flex justify-content-between">
            <div>
              {formik.touched.description && formik.errors.description ? (
                <Form.Control.Feedback className="d-block" type="invalid">
                  {formik.errors.description}
                </Form.Control.Feedback>
              ) : null}
            </div>
            <TextAreaCharCount
              maxChar={feedbackMaxCharAllowed}
              currentCharCount={formik.values.description.length}
            />
          </div>
        </div>
        {/* attach files */}
        <FeedbackFileAttachment
          handleFileUpload={handleFileUpload}
          setAttachmentError={setAttachmentError}
        />
        {attachmentError ? (
          <Form.Control.Feedback className="d-block" type="invalid">
            {attachmentError}
          </Form.Control.Feedback>
        ) : null}
        {/* submit button */}
        <div className="d-flex justify-content-end mt-4">
          <button type="submit" className="btn btn-primary btn-modal">
            {isLoading ? (
              <Spinner animation="border" variant="light" />
            ) : (
              "Send Feedback"
            )}
          </button>
        </div>
      </Form>
    </Modal>
  );
};
