import { useState, useEffect } from "react";
import axios from "axios";
import { setUserChatHistory, setUserDataConsent, setUserInfo, setUserMessagePreferences } from "store/slice/userInfoSlice";
import { user_routes } from "utilities/apiRoutes";
import { Auth } from "aws-amplify";
import { useLocation } from "react-router-dom";
import directLineDisconnect from "botframework-webchat-core/lib/actions/disconnect";
import { createDirectLine, createStore } from "botframework-webchat";
import { useSelector, useDispatch } from "react-redux";
import {
  getDirectLineToken,
  reconnectToConversation,
} from "utilities/commonMethod";
import { setUserTracking, trackUserLoginFromFacebook, trackUserLoginFromGoogle } from "../utilities/mixPanel";
import { modalNames } from "assets/data/data";
import { setModalOpen } from "store/slice/modalSlice";
import { routes } from "routes/Routes";
import { setTheme } from "store/slice/themeSlice";
const useApp = () => {
  const [show, setShow] = useState(false);
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [directLine, setDirectLine] = useState();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const modal = useSelector((state) => state.modal.name);
  const { selectedConversation } = useSelector(
    (state) => state.savedConversations
  );
  const userInfo = useSelector((state) => state.userInfo);
  const dataConsent = useSelector ((state) => state.userInfo.isDataConsent)
  const theme = useSelector((state) => state.theme.theme);
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    const isMobile = window.innerWidth <= 768;

    if (isMobile) {
      setTimeout(() => {
        setShowSplash(false);
      }, 3000);
    } else {
      setShowSplash(false);
    }
  }, []);
  useEffect(()=>{
    if((userInfo.accessToken !== "" && userInfo.accessToken !== null) && (dataConsent === "" || dataConsent === "false")
    && (pathname === routes.main)) {
      dispatch(
        setModalOpen({
          name: modalNames.dataConsent,
        })
      );
    }
  },[dataConsent,userInfo.accessToken,pathname])
  useEffect(() => {
    if (selectedConversation !== null) return;
    if (pathname !== "/") return;
    getDirectLineToken().then(({ token }) => {
      setDirectLine(createDirectLine({ token }));
    });
  }, [selectedConversation, pathname]);
  useEffect(() => {
    setShow(false);
    if (pathname !== "/") return;
    if (!directLine && selectedConversation === null) {
      createNewConnection();
    }
  }, [directLine]);

  useEffect(() => {
    async function oldConnection() {
      if (selectedConversation === null) return;
      disconnect();

      const { token } = await reconnectToConversation(
        selectedConversation.conversationId
      );
      if (token) {
        setDirectLine(createDirectLine({ token }));
      }
    }
    oldConnection();
  }, [selectedConversation]);
  
  const storeMiddleware = () => (next) => (action) => {
    if (action.type === "DIRECT_LINE/DISCONNECT_FULFILLED") {
      setDirectLine(null);
      setStore(createStore({}, storeMiddleware));
      // // if (selectedConversation !== null) return;
      // getDirectLineToken().then(({ token }) => {
      //   setDirectLine(createDirectLine({ token }));
      // });
    }

    return next(action);
  };

  const [store, setStore] = useState(createStore({}, storeMiddleware));

  const disconnect = () => store.dispatch(directLineDisconnect());

  const createNewConnection = () => {
    disconnect();
    getDirectLineToken().then(({ token }) => {
      setDirectLine(createDirectLine({ token }));
    });
  };

  useEffect(() => {
    async function getUser() {
      try {
        setIsAuthLoading(true);
        const user = await Auth.currentUserInfo();
        const tokens = await Auth.currentSession();

        if (user.username.includes('google')) {
          trackUserLoginFromGoogle(user.attributes.email)
        }

        if (user.username.includes('facebook')) {
          trackUserLoginFromFacebook(user.attributes.email)
        }

        if (user && !userInfo.accessToken) {
          // * post the user to backend to update the user info

          if (user && tokens) {
            const userAttr = await axios.get(
              `${user_routes.getUserAttributes}/${user.attributes.sub}`,
              {
                headers: {
                  Authorization: `Bearer ${tokens.accessToken.jwtToken}`,
                },
              }
            );
            
            if (userAttr.data) {
              setUserTracking(user.attributes.email, userAttr?.data["custom:firstName"], userAttr?.data["custom:lastName"])
              dispatch(
                setUserInfo({
                  accessToken: tokens.accessToken.jwtToken,
                  cIdToken: tokens.idToken.jwtToken,
                  cRefreshToken: tokens.refreshToken.token,
                  email: user.attributes.email,
                  userID: user.attributes.sub,
                  createdAt: userAttr.data.createdAt,
                  firstName: userAttr?.data["custom:firstName"],
                  lastName: userAttr?.data["custom:lastName"],
                  isMessagePreferences: userAttr?.data["custom:messagePreference"] || "",
                })
              );
              dispatch(
                setUserDataConsent({
                  isDataConsent:userAttr?.data["custom:isFirstLoggedIn"] || "",
                })
              );
              dispatch(
                setUserChatHistory({
                  isChatHistory:userAttr?.data["custom:isChatHistory"] || "",
                })
              );
              dispatch(
                setUserMessagePreferences({
                  isMessagePreferences: userAttr?.data["custom:messagePreference"] || "",
                })
              )
              if(userAttr?.data["custom:isDarkMode"]){
                if(userAttr?.data["custom:isDarkMode"] === "true"){
                  dispatch(setTheme("dark"))
                } else {
                  dispatch(setTheme("light"))
                }
              }
              else {
                const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
                if(mediaQuery.matches) {
                  dispatch(setTheme("dark"))
                }
                else {
                  dispatch(setTheme("light"))
                }
              }
            }
          }
        }
      } catch (error) {
      } finally {
        setIsAuthLoading(false);
      }
    }
    getUser();
  }, []);

  useEffect(() => {
    if (!userInfo.accessToken) {
      document.body.classList.remove("dark-theme");
      return;
    }
    if (theme === "light") {
      document.body.classList.remove("dark-theme");
    } else {
      document.body.classList.add("dark-theme");
    }
  }, [theme, userInfo]);
  return {
    directLine,
    store,
    show,
    modal,
    setShow,
    userInfo,
    isAuthLoading,
    createNewConnection,
    showSplash
  };
};

export default useApp;
