import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setConversationFlag, setRemoveSearchedConversation, setSearchedConversations } from "store/slice/saveConversationsSlice";
import { user_routes } from "utilities/apiRoutes";
import useRefreshToken from "./useRefreshToken";
import { setAlert } from "store/slice/alertSlice";

const useSidebar = () => {
    const dispatch = useDispatch();
    const [searchedConversation,setSearchedConversation] = useState({});
    const [inputValue, setInputValue ] = useState("");
    const { conversations , searchedConversations } = useSelector(
        (state) => state.savedConversations
      );
   const { accessTokenInterceptor } = useRefreshToken();
   const { accessToken, userID } =
    useSelector((state) => state.userInfo);
    useEffect(()=>{
      if(searchedConversations !== null ) {
        setSearchedConversation(searchedConversations)
      } else {
        setSearchedConversation(conversations);
        dispatch(setConversationFlag({conversationFlag:false}));
      }
    },[searchedConversations,conversations]);
    useEffect(()=>{
      if(Object.keys(searchedConversation).length === 0 && inputValue !== "") {
        dispatch(setConversationFlag({conversationFlag:true}))
      }
    },[searchedConversation])
    const handleDarkLightMode = async (theme) => {
      try {
        const response = await accessTokenInterceptor.put(
          `${user_routes.getUserAttributes}/${userID}`,
          {
              "custom:isDarkMode": theme === "light" ? "true" : "false",
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
      } catch (error) {
        if (error) {
          dispatch(
            setAlert({
              type: "error",
              title: error.response?.data.errorDetails,
            })
          );
        }
      }
    }
    const handleSearchConversation = (e) => {
        const searchedRecords = {};
        if (e.target.value[0] === ' ') {
            setInputValue(e.target.value.trim());
        } else {
            setInputValue(e.target.value);
        }
        if(e.target.value[0] === ' ' && e.target.value.length <2 ){
            dispatch(setRemoveSearchedConversation());
            return;
        }
        Object.keys(conversations).map((key) => {
            const matchingValues = conversations[key].filter((item) => item?.name?.toLowerCase().includes(e.target.value?.toLowerCase()))
                if (matchingValues.length > 0) {
                    searchedRecords[key] = matchingValues;
                }
            });
         if(e.target.value === ""){
            dispatch(setRemoveSearchedConversation());   
         } else {
            dispatch(setConversationFlag({conversationFlag:false}))
            dispatch(setSearchedConversations(searchedRecords));
         } 
    }
  return {
    handleSearchConversation,
    searchedConversation,
    inputValue,
    handleDarkLightMode
  }
}
export default useSidebar;
