import React from "react";
import Modal from "components/Modal";
import FormField from "components/FormField";
import { Row, Col, Button, Form, Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "./accountSettings.scss";
import useAccountSettings from "hooks/useAccountSettings";
import Icon from "components/IconMoon/Icon";
import ResetPasswordModal from "pages/UserManagement/ResetPasswordModal";
export const AccountSettings = ({ isVisible, disconnect }) => {
  const {
    formik,
    email,
    isLoading,
    phoneError,
    handleOpenChangePasswordModal,
    handlePhoneNumberChange,
    setModalSlice,
    modalSlide,
    handleChange,
    chatHistory,
    handleChatHistory,
    handleOpenDeleteAccountModal
  } = useAccountSettings(isVisible);

  const privacyPolicyUrl = 'https://rega-files.s3.ca-central-1.amazonaws.com/REGAPrivacyPolicy.pdf';

  return (
    <Modal size={"lg"} show={isVisible} title="Account Settings">
      {/* First and last name */}
      <div className="container__main">
        <div className="side_bar">
          <button className={`btn mb-3 me-2 ${modalSlide === "generalSettings" && "active"
            }`}
            type="button"
            onClick={() => setModalSlice("generalSettings")}
          >
            <Icon icon="setting" size={28} className="me-2" />
            General Settings
          </button>
          <button
            className={`btn mb-3 me-2 ${ modalSlide === "resetPassword" && "active"
              }`}
            type="button"
            onClick={() => setModalSlice("resetPassword")}
          >
            <Icon icon="reset-password" size={22} className="me-2" />
            Change Password
          </button>
          <button
            className={`btn mb-3 ${modalSlide === "dataConsent" && "active"
              }`}
            type="button"
            onClick={() => setModalSlice("dataConsent")}
          >
            <Icon icon="Database" size={28} className="me-2" />
            Data Consent
          </button>
          
        </div>
        { modalSlide === "generalSettings" ? <>
          <div>
            <Form
              noValidate
              autoComplete="off"
              onSubmit={formik.handleSubmit}
            >
              <Row>
                <Form.Group
                  className="mb-4"
                  as={Col}
                  md="6"
                  xs="12"
                  controlId="validationCustom02"
                >
                  <FormField
                    label="First Name"
                    type="text"
                    placeholder="First Name"
                    name="name"
                    isInvalid={formik.errors.firstName}
                    isValid={formik.touched.firstName && !formik.errors.firstName}
                    error={formik.errors.firstName}
                    {...formik.getFieldProps("firstName")}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-4"
                  as={Col}
                  md="6"
                  xs="12"
                  controlId="validationCustom02"
                >
                  <FormField
                    label="Last Name"
                    type="text"
                    placeholder="Last Name"
                    isInvalid={formik.errors.lastName}
                    isValid={formik.touched.lastName && !formik.errors.lastName}
                    error={formik.errors.lastName}
                    {...formik.getFieldProps("lastName")}
                  />
                </Form.Group>
              </Row>
              {/* Email and phone number */}
              <Row>
                <Form.Group
                  className="mb-4"
                  as={Col}
                  md="6"
                  xs="12"
                  controlId="validationCustom02"
                >
                  <FormField
                    label="Email Address"
                    type="text"
                    placeholder="abc@gmail.com"
                    value={email}
                    disabled
                  />
                </Form.Group>
                <Form.Group
                  className="mb-4"
                  as={Col}
                  md="6"
                  xs="12"
                  controlId="validationCustom02"
                >
                  <label className="form-label">Phone Number</label>
                  <PhoneInput
                    defaultCountry="CA"
                    international
                    // countryCallingCodeEditable={false}
                    value={formik.values.phoneNumber}
                    placeholder="Phone Number"
                    onChange={(value) => handlePhoneNumberChange(value)}
                  />
                  {phoneError ? (
                    <div className="invalid-feedback d-block">{phoneError}</div>
                  ) : null}
                </Form.Group>
              </Row>
               {/* <div className="d-flex justify-content-between">
                <p>Delete Account</p>
                <Button onClick={handleOpenDeleteAccountModal} variant="danger" className="text-white">Delete</Button>
               </div> */}
              <button
                className="btn text-primary border-color-none mb-3 ps-0 password-btn"
                type="button"
                onClick={handleOpenDeleteAccountModal}
              >
                <u><p>Delete Account</p></u>
              </button>
              {/* Save button */}
              <div className="d-flex justify-content-center ">
                <Button
                  variant="primary"
                  type="submit"
                  className=" btn-modal"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </> : modalSlide === "resetPassword" ?   <>
        <ResetPasswordModal/>
          
        </> :modalSlide === "dataConsent" ? <>
        <div className="consent-containers">
            <div className="d-flex mt-3">
              <div>
                <h4>
                  Chat History & Model Training
                </h4>
                <p className="text-justify">
                  Save new chats on this browser to your history and allow them to be used to improve our models.
                  Unsaved chats will be deleted from our systems within 30 days.
                  This setting does not sync across browsers or devices. <u><a href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer">
                    Learn more
                  </a>
                  </u>
                </p>
              </div>
              <div className="d-flex justify-content-center items-center text-center">
                <div class="form-check form-switch">
                  <input class="form-check-input" checked={chatHistory}
                    type="checkbox"
                    onChange={handleChange}
                    id="flexSwitchCheckDefault" />
                </div>
              </div>
            </div>
            <div >
              <Button
                variant="primary"
                className=" btn-modal"
                disabled={isLoading}
                onClick={() => {
                  handleChatHistory()
                  disconnect()
                }}
              >
                {isLoading ? (
                  <Spinner animation="border" variant="light" />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </div>
        </> : null}
      </div>
    </Modal>
  );
};
