import React from "react";
import Modal from "components/Modal";
import "./siteFeedbackOptionsModal.scss";
import Icon from "components/IconMoon/Icon";
import { modalNames } from "assets/data/data";
import { setModalOpen } from "store/slice/modalSlice";
import { useDispatch } from "react-redux";
const feedbackTypes = [
  {
    icon: "bug",
    title: "Report a Bug",
    description: "Let us know what's broken",
    modal: modalNames.bugReportFeedback,
  },
  {
    icon: "feedback",
    title: "General Feedback",
    description: "You can provide general feedback about the platform here",
    modal: modalNames.generalFeedback,
  },
  {
    icon: "feature-request",
    title: "Feature Request",
    description: "Tell us what features do you want to see in REGA",
    modal: modalNames.featureRequest,
  },
];
export const SiteFeedbackOptionsModal = ({ isVisible }) => {
  const dispatch = useDispatch();
  const handleOpenModal = (modal) => {
    dispatch(setModalOpen({ name: modal }));
  };
  return (
    <Modal Modal title="Feedback" show={isVisible}>
      <div className="container__feedbackType">
        {feedbackTypes.map(({ icon, title, description, modal }) => (
          <div
            className="feedback-type"
            key={title}
            onClick={() => handleOpenModal(modal)}
          >
            <Icon icon={icon} size={32} />
            <h4>{title}</h4>
            <p>{description}</p>
          </div>
        ))}
      </div>
    </Modal>
  );
};
