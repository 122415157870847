import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setAlert } from "store/slice/alertSlice";
import { auth_routes } from "utilities/apiRoutes";
import { routes } from "routes/Routes";
import { useNavigate,useSearchParams } from "react-router-dom";
const useUpdatePassword = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ displayPasswordValidationBullets, setDisplayPasswordValidationBullets ] = useState(false);
    const initialValues = {
        newPassword: "",
        confirmNewPassword: "",
    };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,~`.<>/?]+)(?=.{8,})/,
          " "
        ),
      confirmNewPassword: Yup.string()
        .required("Confirm password is required")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        const response = await axios.post(`${auth_routes.confirmForgotPassword}`, {
          email: searchParams.get("email").replace(" ", "+"),
          newPassword: values.confirmNewPassword,
          verificationCode: searchParams.get("verificationCode"),
        });
        if(response) {
            dispatch(
                setAlert({
                  type: "success",
                  title: "Successfully password updated. Please Login",
                })
              );
            navigate(routes.login)
        }
        setIsLoading(false);
        resetForm();
      } catch (error) {
        setIsLoading(false);
        dispatch(
          setAlert({ type: "error", title: error.response.data.errorDetails })
        );
        resetForm();
      }
    },
    
  });
  useEffect(() => {
    formik.resetForm();
    setDisplayPasswordValidationBullets(false);
  }, []);
  return {
    isLoading,
    formik,
    displayPasswordValidationBullets,
    setDisplayPasswordValidationBullets,
  }
}
export default useUpdatePassword;