import { createSlice } from "@reduxjs/toolkit";
// types of alerts are success , error , warning , info
const initialState = {
  rememberMe: false,
  email: "",
  password: "",
};

export const rememberMe = createSlice({
  name: "rememberMe",
  initialState,
  reducers: {
    setRememberMe: (state, action) => {
      state.rememberMe = action.payload.rememberMe;
      state.email = action.payload.email || "";
      state.password = action.payload.password || "";
    },
    setRemoveRememberMe: (state, action) => {
      state.rememberMe = false;
      state.email = "";
      state.password = "";
    },
  },
});

export const { setRememberMe, setRemoveRememberMe } = rememberMe.actions;

export default rememberMe.reducer;
