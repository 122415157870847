import Modal from 'components/Modal';
import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import { Button } from 'react-bootstrap';
import useRefreshToken from 'hooks/useRefreshToken';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { reva_message_base_url } from 'utilities/apiRoutes';
import { setModalOpen } from 'store/slice/modalSlice';

export const ConversationPositiveFeedbackModal = ({isVisible, positiveFeedbackData}) => {
    const { accessTokenInterceptor } = useRefreshToken();
    const dispatch = useDispatch();
    const { accessToken } = useSelector((state) => state.userInfo);
    const [submitViaButton, setSubmitViaButton] = useState(false);
    const {id} = positiveFeedbackData
    const formik = useFormik({
        initialValues: {
          userFeedback: "",
        },  onSubmit: async (values, { resetForm }) => {
          try {
            
            await accessTokenInterceptor.patch(
              `${reva_message_base_url}`,
              {
                id: positiveFeedbackData.id,
                conversationId: positiveFeedbackData.conversationId,
                messageFeedback: {
                  ...values,
                },
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );
            if (isVisible) {
                positiveFeedbackData.feedBackFunc({id:id,feedBack:true})
              dispatch(setModalOpen({ name: "", message:null}));
            }
            resetForm();
          } catch (error) {
            console.log(error);
          }
        },
      });
      // useEffect to reset form values when modal is closed
      useEffect(() => {
          formik.resetForm();
      }, [isVisible]);
  return (
    <Modal  show={isVisible} title="Additional Feedback" showHeaderIcon positiveFeedback={true}>
        <form className="mt-2" onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <textarea
            className="form-control new-placeholder"
            id="negativeFeedback"
            rows="3"
            placeholder="What do you like about the response?"
            {...formik.getFieldProps("userFeedback")}
          ></textarea>
        </div>
        <div className=" d-flex justify-content-end">
          <Button
            variant="primary"
            type="submit"
            className="btn-modal mt-3"
            onClick={() => setSubmitViaButton(true)}
          >
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  )
}
