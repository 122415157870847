import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  title: "Welcome Back",
};

export const headerSlice = createSlice({
  name: "headerSlice",
  initialState,
  reducers: {
    setHeaderTitle: (state, action) => {
      state.title = action.payload || "Welcome Back";
    },
  },
});

export const { setHeaderTitle } = headerSlice.actions;

export default headerSlice.reducer;
