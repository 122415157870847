import React, { useState, useEffect } from "react";
import Modal from "components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setModalOpen } from "store/slice/modalSlice";
import { useFormik } from "formik";
import { reva_message_base_url } from "utilities/apiRoutes";
import useRefreshToken from "hooks/useRefreshToken";
import { Button } from "react-bootstrap";
export const ConversationNegativeFeedbackModal = ({
  isVisible,
  negativeFeedbackData,
}) => {
  const { accessTokenInterceptor } = useRefreshToken();
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.userInfo);
  const [submitViaButton, setSubmitViaButton] = useState(false);
  const {id} = negativeFeedbackData 
  const formik = useFormik({
    initialValues: {
      userFeedback: "",
      isHarmfulMessage: false,
      isntHelpful: false,
      isntTrue: false,
    },  onSubmit: async (values, { resetForm }) => {
      try {
        
        await accessTokenInterceptor.patch(
          `${reva_message_base_url}`,
          {
            id: negativeFeedbackData.id,
            conversationId: negativeFeedbackData.conversationId,
            messageFeedback: {
              ...values,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (isVisible) {
          negativeFeedbackData.feedBackFunc({id:id,feedBack:true})
          dispatch(setModalOpen({ name: "", message:null}));
        }
        resetForm();
      } catch (error) {
        console.log(error);
      }
    },
  });
  // useEffect to reset form values when modal is closed
  useEffect(() => {
      formik.resetForm();
  }, [isVisible]);
  return (
    <Modal show={isVisible} title="Submit Response" showHeaderIcon>
      <form className="mt-2" onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <textarea
            className="form-control"
            id="negativeFeedback"
            rows="3"
            placeholder="Please tell us what was the issue with the response. How could it to be improved?"
            {...formik.getFieldProps("userFeedback")}
          ></textarea>
        </div>
        <div className="form-group  mt-2">
          <div class="form-check mb-1">
            <input
              class="form-check-input"
              type="checkbox"
              id="isHarmfulMessage"
              value={formik.values.isHarmfulMessage}
              {...formik.getFieldProps("isHarmfulMessage")}
            />
            <label
              class="form-check-label"
              for="isHarmfulMessage"
              style={{ marginLeft: "8px" }}
            >
              This is harmful/unsafe
            </label>
            <div class="form-check mb-1">
              <input
                class="form-check-input mb-1"
                type="checkbox"
                id="isntHelpful"
                {...formik.getFieldProps("isntHelpful")}
              />
              <label
                class="form-check-label  "
                for="isntHelpful"
                style={{ marginLeft: "8px" }}
              >
                This isn't true
              </label>
            </div>
            <div class="form-check mb-1">
              <input
                class="form-check-input"
                type="checkbox"
                id="isntTrue"
                {...formik.getFieldProps("isntTrue")}
              />
              <label
                class=" form-check-label "
                for="isntTrue"
                style={{ marginLeft: "8px" }}
              >
                This isn't helpful
              </label>
            </div>
          </div>
        </div>
        <div className=" d-flex justify-content-end">
          <Button
            variant="primary"
            type="submit"
            className="btn-modal"
            onClick={() => setSubmitViaButton(true)}
          >
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};
