import FaqsTypes from 'components/FaqsTypes';
import Main from 'components/Main';
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router';
import { setHeaderTitle } from "store/slice/headerSlice";
import "./FAQ.scss";
import { setRemoveSelectedConversation } from 'store/slice/saveConversationsSlice';
export function FAQ({ setShow }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(()=>{
      dispatch(setHeaderTitle("FAQs"));
     dispatch(setRemoveSelectedConversation());
    navigate("general");

    },[])
    
  return (
    <>
    <Main setShow={setShow}>
    <div className="faq-page h-100">
        <div className="d-flex flex-column h-100 w-100">
            <div className='overflow-auto scroll-bar'>
            <FaqsTypes />
            <Outlet/>
            </div>
        </div>
     </div>
    </Main>
    
    </>
  )
};
