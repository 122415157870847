import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import "./Sidebar.scss";
import { Link } from "react-router-dom";
import Icon from "components/IconMoon/Icon";
import { useSelector, useDispatch } from "react-redux";
import { setConversation, setOnGoingConversationId } from "store/slice/conversationSlice";
import { setModalOpen } from "store/slice/modalSlice";
import { setHeaderTitle } from "store/slice/headerSlice";
import SavedConversation from "components/SavedConversation";
import { setRemoveSelectedConversation } from "store/slice/saveConversationsSlice";
import { modalNames } from "assets/data/data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faClose } from "@fortawesome/free-solid-svg-icons";
import AccountSettings from "components/AccountSettings";
import LogoutModal from "components/LogoutModal";
import UpdatePasswordModal from "pages/UserManagement/ResetPasswordModal";
import { setTheme } from "store/slice/themeSlice";

import { routes } from "routes/Routes";
import ClearConversationsModal from "components/ClearConversationsModal";
import { useNavigate } from "react-router-dom";
import {
  trackPageVisitDuration,
  trackPageVisitStart,
} from "utilities/mixPanel";
import useAccountSettings from "hooks/useAccountSettings";
import useSidebar from "hooks/useSidebar";
import DeleteAccount from "components/DeleteAccount";
import CustomizeResponseModal from "components/CustomizeResponseModal";
import ConfirmModal from "components/ConfirmModal";
export const Sidebar = ({ show, setShow, createNewConnection }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const modal = useSelector((state) => state.modal.name);
  const { email } = useSelector((state) => state.userInfo);

  const handleSideBarClose = () => {
    setShow(false);
  };
  const handleLogout = () => {
    dispatch(setModalOpen({ name: modalNames.logout }));
  };
  const handleNewChat = () => {
    dispatch(setOnGoingConversationId(""))
    dispatch(setRemoveSelectedConversation());
    dispatch(setHeaderTitle());
    handleSideBarClose();
    navigate(routes.main);
    dispatch(
      setConversation({
        fieldFocus: true,
        conversationText: "",
      })
    );
    createNewConnection();
  };
  const handleOpenAccountSettings = () => {
    dispatch(setModalOpen({ name: modalNames.accountSettings }));
  };
  const theme = useSelector((state) => state.theme.theme);
  const { isChatHistory } = useSelector((state) => state.userInfo);
  const { handleTurnOnHistory } = useAccountSettings();
  const { handleSearchConversation ,inputValue ,handleDarkLightMode} = useSidebar();
  const handleThemeChange = () => {
    dispatch(setTheme(theme === "light" ? "dark" : "light"));
    handleDarkLightMode(theme)
  };

  const handleViewPrompts = () => {
    dispatch(setModalOpen({ name: modalNames.viewPrompts }));
  };

  useEffect(() => {
    trackPageVisitStart("REGA Web Visit Duration", email);

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        trackPageVisitDuration("REGA Web Visit Duration");
      } else {
        trackPageVisitStart("REGA Web Visit Duration", email);
      }
    };

    window.addEventListener("beforeunload", () => {
      trackPageVisitDuration("REGA Web Visit Duration");
    });

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", () => { });
      trackPageVisitDuration("REGA Web Visit Duration");
    };
  }, []);

  return (
    <>
      <LogoutModal isVisible={modalNames.logout === modal} />
      <CustomizeResponseModal isVisible={modalNames.customizeResponse === modal} />
      <ConfirmModal isVisible={modalNames.confirmationModal === modal}></ConfirmModal>
      <AccountSettings isVisible={modalNames.accountSettings === modal}
        disconnect={createNewConnection} />
      <DeleteAccount isVisible={modalNames.deleteAccount === modal}
      />
      <ClearConversationsModal
        isVisible={modalNames.clearSavedConversations === modal}
        disconnect={createNewConnection}
      />
      <div
        className={`reva-sidebar-backdrop fade ${show ? "show d-block" : " d-none"
          }`}
        onClick={handleSideBarClose}
      ></div>
      <div
        className={`reva-sidebar h-100 d-flex `}
        style={{ transform: show ? "translateX(0%)" : "translateX(-100%)" }}
      >
        <div className="d-flex flex-column w-100">
          <div className="h-100 w-100 flex-grow-1">
            <nav className="d-flex flex-column h-100 flex-grow-1">
              <Button
                variant="icon"
                className="d-md-none position-relative t-0 r-0 p-0 w-auto h-auto border-0 ms-auto mb-3"
                onClick={handleSideBarClose}
              >
                {theme === "light" ? (
                  <FontAwesomeIcon
                    icon={faClose}
                    size="lg"
                    className="text-dark m-0"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faClose}
                    size="lg"

                    className="text-white m-0"
                  />
                )}
              </Button>
              <Button
                variant="primary"
                className="d-flex justify-content-center align-items-center mb-px-22 gap-2"
                onClick={handleNewChat}
              >
                <Icon icon="plus" color="red" size={18} />
                New chat
              </Button>
              <Button
                variant="link"
                onClick={handleViewPrompts}
                className="side-bar-view-prompt text-start p-0 h-auto border-0  me-3 mb-px-22">
                View Prompts
                <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
              </Button>
              <div className="sidebar-search-bar">
              <input class="form-control mb-px-20" placeholder="Search"
                value={inputValue}
                onChange={(e)=>handleSearchConversation(e)}/>
                <Icon icon="search" className="search-icon" size={22}/>
              </div>
              {isChatHistory === "true" ? <>
                <div className=" overflow-auto scroll-bar saved-chats-list">
                  <div className="d-flex flex-column">
                    <SavedConversation
                      createNewConnection={createNewConnection}
                    />
                  </div>
                </div></> : <>
                <div className=" overflow-auto scroll-bar saved-chats-list">
                  <p className="fs-px-14 fw-bold">Chat history is off for this browser.</p>
                  <p>When chat history is disabled, new chats on this browser won’t be visible in your chat history across any of your devices.</p>
                  <p className="fs-px-14 fw-bold text-primary mt-px-22 chat-history"
                    onClick={() => {
                      handleTurnOnHistory()
                      handleNewChat()
                    }}>
                    Turn On Chat History
                  </p>
                </div>
              </>}
              <hr className="m-1" />
              <Link
                to="#"
                className="d-flex align-items-center gap-2 my-2 mt-3"
                onClick={() =>
                  dispatch(
                    setModalOpen({ name: modalNames.customizeResponse})
                  )
                }
              >
                <Icon icon="custom-response" size={20} />
                Customize Response
              </Link>
              <Link
                to="#"
                className="d-flex align-items-center gap-2 my-2"
                onClick={() =>
                  dispatch(
                    setModalOpen({ name: modalNames.clearSavedConversations })
                  )
                }
              >
                <Icon icon="trash" size={16} />
                Clear Chats
              </Link>
              <Link
                to="#"
                onClick={handleThemeChange}
                className="d-flex align-items-center gap-2 my-2"
              >
                {theme === "light" ? (
                  <>
                    <Icon icon="moon" size={16} />
                    <span> Dark Mode</span>
                  </>
                ) : (
                  <>
                    <Icon icon="sun" size={16} />
                    <span> Light Mode</span>
                  </>
                )}
              </Link>
              <Link
                to="faq/general"
                className="d-flex align-items-center gap-2 my-2"
              >
                <Icon icon="question-circle" size={16} />
                FAQs
              </Link>
              <Link
                to="#"
                className="d-flex align-items-center gap-2 my-2"
                onClick={handleOpenAccountSettings}
              >
                <Icon icon="user" size={16} />
                Account
              </Link>
              <hr className="m-1" />
              <Link
                to="#"
                className="d-flex align-items-center gap-2 mt-2"
                onClick={handleLogout}
              >
                <Icon icon="arrow-from-right" size={16} />
                Log out
              </Link>
            </nav>
          </div>
        </div>
      </div >
    </>
  );
};
