import React, { useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip as CustomTooltip } from "react-bootstrap";

export const Tooltip = ({ placement , text, children }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <OverlayTrigger
      placement={placement ? placement : "top"}
      trigger={isMobile ? [] : ["hover"]}
      overlay={<CustomTooltip id={`tooltip-disabled`}>{text}</CustomTooltip>}
    >
      {children}
    </OverlayTrigger>
  );
};

