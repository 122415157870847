import React from "react";
import { Link } from "react-router-dom";

export default function Typography() {
  return (
    <div class="container p-20">
      <h1 class="text-capitalize fw-semibold">Heading 1</h1>
      <h2>Heading 2</h2>
      <h3>Heading 3</h3>
      <h4>Heading 4</h4>
      <h5>Heading 5</h5>
      <h6>Heading 6</h6>
      <hr />
      <h2>Body Text</h2>
      <p class="pt-px-23">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
      <hr />
      <div class="flex">
        <button class="btn">Button</button>
        <button class="btn btn-primary">Button Primary</button>
        <button class="btn btn-secondary">Button Secondary</button>
        <button class="btn btn-danger">Button Danger</button>
        <button class="btn btn-outline-primary">Button Tertiary</button>
        <br />
        <br />
        <button
          class="btn-secondary btn-gray-border"
          data-bs-toggle="modal"
          data-bs-target="#bookMeeting"
        >
          Gray border button
        </button>
      </div>
      <hr />
      <div class="flex">
        <button class="btn btn-icon">{/* <Cog8ToothIcon /> */}</button>
        <button class="btn btn-icon btn-primary">
          {/* <Cog8ToothIcon /> */}
        </button>
        <button class="btn btn-secondary btn-icon">
          {/* <Cog8ToothIcon /> */}
        </button>
        <button class="btn btn-outline-primary btn-icon">
          {/* <Cog8ToothIcon /> */}
        </button>
        <button class="btn btn-danger btn-icon">
          {/* <Cog8ToothIcon /> */}
        </button>
      </div>
      <hr />
      <div class="flex space-x-3">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">
            Email address
          </label>
          <input
            type="email"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="name@example.com"
          />
        </div>
        <div class="mb-3">
          <label for="exampleFormControlTextarea1" class="form-label">
            Example textarea
          </label>
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
          ></textarea>
        </div>
        <div class="input-group mb-3">
          <span class="input-icon">{/* <Cog8ToothIcon /> */}</span>
          <input
            type="text"
            class="form-control"
            placeholder="Username"
            aria-label="Username"
            aria-describedby="basic-addon1"
          />
        </div>
        <select class="form-select mb-3" aria-label="Default select example">
          <option>Open this select menu</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select>
        <div class="input-group mb-3">
          <span class="input-icon">{/* <Cog8ToothIcon /> */}</span>
          <select class="form-select" aria-label="Default select example">
            <option>Open this select menu</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>
        <div class="input-group mb-3">
          <span class="input-icon">{/* <Cog8ToothIcon /> */}</span>
          <input type="text" />
        </div>
      </div>
      <hr />
      <h2>UL listings</h2>
      <ul>
        <li>
          <Link to="/typography" class="fw-bold">
            Testing Text
          </Link>
        </li>
        <li>
          <Link to="/typography" class="fw-semibold">
            Testing Text
          </Link>
        </li>
        <li>
          <Link to="/typography"> Testing Text </Link>
        </li>
        <li>
          <Link to="/typography" class="fw-light">
            Testing Text
          </Link>
        </li>
      </ul>
    </div>
  );
}
