import React from "react";
import "./AccountLayout.scss";
import  frame from "assets/svg/Frame.svg"
export const AccountLayout = ({ children }) => {
  return (
    <main className="posistion-relative h-100 d-flex user-pages-main-wrapper">
      <div className="auth-left-panel h-100 d-flex align-items-center justify-content-center">
        <div className="auth-left-panel-inner scroll-bar overflow-auto w-100">
          <div>{children}</div>
        </div>
      </div>
      <div className="auth-right-panel h-100 d-none d-md-block flex-fill position-relative">
        <span className="left-frame"></span>
        <span className="right-frame"></span>
        <div className="auth-right-panel-inner d-flex flex-column align-items-center justify-content-center scroll-bar overflow-auto h-100">
        <img src={frame} alt="logo" className="img-fluid" />
        </div>
      </div>
    </main>
  );
};
