import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  conversations: {},
  selectedConversation: null,
  searchedConversations: null,
  conversationFlag: false,
};

//! each object in save conversation will have a title and and activity array

export const saveConversationSlice = createSlice({
  name: "saveConversation",
  initialState,
  reducers: {
    setSaveConversation: (state, action) => {
      state.conversations = {...action.payload};
    },
    setSearchedConversations: (state, action) => {
      state.searchedConversations = {...action.payload};
    },
    setUpdateConversations: (state, action) => {
      //! add the new conversation to the object and make sure the object size stays 5
      const todayMessages = state.conversations.today;
     state.conversations.today = [action.payload,...todayMessages]
    },
    setClearAllConversations: (state) => {
      state.conversations = {};
    },
    setRemoveConversationById: (state, action) => {
      
      Object.keys(state.conversations).map((key)=>{
        if(key === action.payload.key){
          const newArray = [...state.conversations[action.payload.key]];
          newArray.splice(action.payload.index, 1);
          state.conversations[action.payload.key]= newArray;
        }
      })
    },
    setUpdateConversationName: (state, action) => {
      // ! find the conversation by id and update its name
      const conversation =  Object.values(state.conversations).flat().find(record => record.conversationId === action.payload.id);
      if (conversation) {
        const newArray = [...state.conversations[action.payload.key]];
        newArray[action.payload.index].name = action.payload.name;
        state.conversations[action.payload.key] = newArray;
      }
    },
    setAddConversationTokenById: (state, action) => {
      // ! find the conversation by id and add a token to it
      const conversation = state.conversations.find(
        (conversation) =>
          conversation.conversationId === action.payload.conversationId
      );
      if (conversation) {
        const index = state.conversations.indexOf(conversation);
        state.conversations[index].token = action.payload.token;
        state.conversations[index].waterMark = action.payload.waterMark;
      }
    },
    setSelectedConversation: (state, action) => {

      const conversation =  Object.values(state.conversations).flat().find(record => record.conversationId === action.payload);
      if (conversation) {
        state.selectedConversation = conversation;
      }
    },
    setRemoveSelectedConversation: (state) => {
      state.selectedConversation = null;
    },
    setRemoveSearchedConversation: (state) => {
      state.searchedConversations = null;
    },
    setConversationFlag: (state ,action) => {
      state.conversationFlag = action.payload.conversationFlag;
    },
  },
});

export const {
  setSaveConversation,
  setUpdateConversations,
  setClearAllConversations,
  setRemoveConversationById,
  setAddConversationTokenById,
  setSelectedConversation,
  setRemoveSelectedConversation,
  setUpdateConversationName,
  setSearchedConversations,
  setRemoveSearchedConversation,
  setConversationFlag
} = saveConversationSlice.actions;

export default saveConversationSlice.reducer;
