import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faClose } from "@fortawesome/free-solid-svg-icons";
import "./feedbackFileAttachment.scss";
export const FeedbackFileAttachment = ({
  handleFileUpload,
  setAttachmentError,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleFileChange = (e) => {
    setUploadedFiles([...uploadedFiles, ...Array.from(e.target.files)]);
    handleFileUpload([...uploadedFiles, ...Array.from(e.target.files)]);
  };
  const handleRemoveFile = (index) => {
    const files = Array.from(uploadedFiles);
    files.splice(index, 1);
    setUploadedFiles(files);
    handleFileUpload(files);
  };
  useEffect(() => {
    if (uploadedFiles.length > 3) {
      setAttachmentError("You can upload maximum 3 files");
      return;
    }
    let totalFileSize = 0;
    for (let i = 0; i < uploadedFiles.length; i++) {
      totalFileSize += uploadedFiles[i].size;
    }
    if (totalFileSize > 25000000) {
      setAttachmentError("Total file size should be less than 25MB");
      return;
    }
    setAttachmentError("");
  }, [uploadedFiles]);
  return (
    <div className="attachments">
      <div className="d-flex flex-wrap gap-2">
        {Array.from(uploadedFiles).length > 0
          ? Array.from(uploadedFiles).map((file, index) => (
              <div
                className="file-name d-flex justify-content-center align-items-center gap-2 mb-2"
                key={file.lastModified}
              >
                <p className="fw-semibold" title={file.name}>{file.name}</p>
                <FontAwesomeIcon
                  icon={faClose}
                  className="ml-2 "
                  onClick={() => handleRemoveFile(index)}
                />
              </div>
            ))
          : null}
      </div>
      <input
        className="form-control"
        multiple
        type="file"
        accept="image/jpeg,image/png,image/gif, .pdf, .doc, .docx, .xls, .xlsx, .txt"
        id="formFile"
        onChange={handleFileChange}
      />
      <label htmlFor="formFile" className="form-label ">
        <div className="container__attachments rounded">
          <FontAwesomeIcon icon={faPaperclip} size="lg" className="mr-2" />
          <span className="font-weight-bold">Attach Files</span>
        </div>
      </label>
      <p className="mt-2 attachment-info">The attachments are optional.</p>
    </div>
  );
};
