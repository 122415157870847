import React from "react";
import "./PasswordValidationBullets.scss";
export const PasswordValidationBullets = ({ password }) => {
  return (
    <ul className="mb-1 validationBullets">
      <li
        className={`${password.length >= 8 ? "bullets-color" : "text-danger"}`}
      >
        Password must be at least 8 characters
      </li>
      <li
        className={`${
          /[A-Z]/.test(password) ? "bullets-color" : "text-danger"
        }`}
      >
        Password must contain one uppercase
      </li>
      <li
        className={`${/(\d)/.test(password) ? "bullets-color" : "text-danger"}`}
      >
        Password must contain one number
      </li>
      <li
        className={`${
          /([a-z])/.test(password) ? "bullets-color" : "text-danger"
        }`}
      >
        Password must contain one lowercase
      </li>
      <li
        className={`${
          /[!@#$%^&*()_+\-=[\]{};':"\\|,~`.<>/?]+/.test(password)
            ? "bullets-color"
            : "text-danger"
        }`}
      >
        Password must contain one special character
      </li>
    </ul>
  );
};
