import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPlus, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { capitalizationRule } from "utilities/commonMethod";
import useHeader from "hooks/useHeader";
import useTextToSpeech from "hooks/useTextToSpeech";
import AudioPlayer from "components/AudioPlayer";
import { useState } from "react";
import Icon from "components/IconMoon/Icon";
import "./Header.scss";
import Tooltip from "components/Tooltip";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setModalOpen } from "../../store/slice/modalSlice";
import { modalNames } from "../../assets/data/data";
import ViewPromptsModal from "../ViewPromptsModal";
export const Header = ({ disconnect, setShow }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const { title, handleNewChat } = useHeader(disconnect);
  const handleShow = () => setShow(true);
  const { handleTextToSpeech, audioArray } = useTextToSpeech();
  const modal = useSelector((state) => state.modal.name);
  const dispatch = useDispatch();

  const onGoingConvo = useSelector(
    (state) => state.conversation.onGoingConversationId
  );

  const handleViewPrompts = () => {
    dispatch(setModalOpen({ name: modalNames.viewPrompts }));
  };
  const handleNewChatForFaqs = () => {
    handleNewChat();
    disconnect();
  }
  const location = useLocation();

  return (
    <header className="position-absolute top-0 d-flex align-items-center w-100 chat-header pt-px-14 pb-px-14 pl-px-12 pr-px-12">
      < ViewPromptsModal isVisible={modalNames.viewPrompts === modal} />

      <button
        type="button"
        className={`p-0 h-auto border-0 d-md-none`}
        onClick={handleShow}
      >
        <span className="visually-hidden">Open sidebar</span>
        <FontAwesomeIcon icon={faBars} size="lg" />
      </button>
      <div className="d-flex justify-content-between w-100 g-2">
        <div className="d-flex">
        <h4 className="flex-grow-1 text-md-start">
        {capitalizationRule(title)}
      </h4>
      {location.pathname === "/" && (
          <Button
            variant="link"
            onClick={handleViewPrompts}
            className="view-prompts p-0 h-auto border-0  me-3">
            View Prompts
            <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
          </Button>
        )}
        </div>
        <div className="d-flex">
        {onGoingConvo && location.pathname === "/" && (
        <>
          {!isPlaying ? (
            <Tooltip placement="left" text="REGA will start speaking">
              <Button
                variant="link"
                className="p-0 h-auto border-0 me-3"
                onClick={handleTextToSpeech}
                size="sm">
                <Icon
                  className="ms-2"
                  icon="mute-mic"
                  size={24}
                />
              </Button>

            </Tooltip>
          ) : (
            <Tooltip placement="left" text="Mute REGA">
              <Button
                variant="link"
                className="p-0 h-auto border-0 me-3"
                size="sm">
                <Icon className="me-2" icon="speaker" size={24} />
              </Button>
            </Tooltip>
          )}
          <AudioPlayer audioUrls={audioArray} setIsPlaying={setIsPlaying} />
        </>
      )}
      <span className="p-0 h-auto border-0 d-md-none" onClick={() => handleNewChatForFaqs()}>
        <FontAwesomeIcon icon={faPlus} size="lg" />
      </span>
        </div>
      </div>
    </header>
  );
};
