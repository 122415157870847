import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { setAlert } from 'store/slice/alertSlice';
import { setConversation } from 'store/slice/conversationSlice';

const useSpeechToText = () => {
    
    const { transcript, listening , resetTranscript} = useSpeechRecognition();
    const dispatch = useDispatch();
    useEffect(()=>{
      if (!SpeechRecognition.browserSupportsSpeechRecognition) {
        dispatch(
          setAlert({
            title: "Speech recognition is not supported in your browser.",
            type: "warning",
          })
        );
      }
      
    },[])
    useEffect(()=>{
      dispatch(
        setConversation({
          conversationText: transcript,
          fieldFocus: true,
        })
      );
    },[transcript])
    const handleSpeechRecognition = () => {
      if (listening) {
        SpeechRecognition.stopListening();
      } else {
        SpeechRecognition.startListening({
         interimResultsInterval: 10000,
         endSilenceTimeout: 10000,
         interimResults: false,
        });
      }
    };
    const checkIdolStateForListening = () => {

      let timeoutId;
      if(listening && transcript !== "") {
      const handleSilence = () => {
        if (listening) {
          SpeechRecognition.stopListening();
        }
      };
      SpeechRecognition.onend = () => {
        timeoutId = setTimeout(handleSilence, 10000);
      };
        clearTimeout(timeoutId);
    }
    }
    const resetMessage = () => {
        resetTranscript();
    }
    const stopListener = () => {
        if(listening) {
            SpeechRecognition.stopListening();
        }
    }
    return {
        handleSpeechRecognition,
        listening,
        transcript,
        SpeechRecognition,
        resetTranscript,
        resetMessage,
        stopListener,
        checkIdolStateForListening
    }
}

export default useSpeechToText;