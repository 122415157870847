import Modal from "components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setModalOpen } from "store/slice/modalSlice";
import {
  setClearAllConversations,
  setRemoveSelectedConversation,
} from "store/slice/saveConversationsSlice";
import { setHeaderTitle } from "store/slice/headerSlice";
import { setConversation } from "store/slice/conversationSlice";
import { reva_conversation_base_url } from "utilities/apiRoutes";
import useRefreshToken from "hooks/useRefreshToken";
import { useLocation, useNavigate } from "react-router";
import { routes } from "routes/Routes";
export const ClearConversationsModal = ({ isVisible, disconnect }) => {
  const dispatch = useDispatch();
  const { accessTokenInterceptor } = useRefreshToken();
  const { userID, accessToken } = useSelector((state) => state.userInfo);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleClearConversation = async () => {
    disconnect();
    if (pathname === routes.main) {
      dispatch(setHeaderTitle());
    }
    dispatch(setClearAllConversations());
    dispatch(setConversation({ fieldFocus: true, conversationText: "" }));
    dispatch(setModalOpen({ name: "" }));
    dispatch(setRemoveSelectedConversation());
    navigate("/");
    try {
      await accessTokenInterceptor.delete(
        `${reva_conversation_base_url}/users/${userID}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    dispatch(setModalOpen({ name: "" }));
  };
  return (
    <Modal title="Clear Chats" show={isVisible}>
      <div className="mt-px-10">
        <p>
          Are you sure you want clear all chats? This action cannot be reversed.
        </p>
        <div className="mt-px-25 d-flex justify-content-end gap-2">
          <button className="btn  bg-white text-primary" onClick={handleClose}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleClearConversation}>
            Yes, Clear
          </button>
        </div>
      </div>
    </Modal>
  );
};
