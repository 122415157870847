import React from "react";
import "./Main.scss";
import Header from "../Header";
export const Main = ({ children, setShow, disconnect }) => {
  return (
    <main className="position-relative h-100 d-flex flex-column main-wrapper">
      <div className="main-wrapper-inside position-relative">
        <div className="h-100 w-100 d-flex flex-column">
          <Header setShow={setShow} disconnect={disconnect} />
          {children}
        </div>
      </div>
    </main>
  );
};
