import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { customizeResponseMaxCharAllowed, modalNames } from "assets/data/data";
import { useState } from "react";
import { setAlert } from "store/slice/alertSlice";
import { user_routes } from "utilities/apiRoutes";
import useRefreshToken from "./useRefreshToken";
import { setModalOpen } from "store/slice/modalSlice";
import { setUserMessagePreferences } from "store/slice/userInfoSlice";

const useCustomizeResponse = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [ enableResponseForChats, setEnableResponseForChats ] = useState(true);
    const { accessTokenInterceptor } = useRefreshToken();
    const { accessToken, userID ,isMessagePreferences} =
    useSelector((state) => state.userInfo);
    const dispatch = useDispatch();
    const handleChange = () =>{
        setEnableResponseForChats(!enableResponseForChats);
      }
    const formik = useFormik({
        initialValues: {
          customizeResponseDescription: isMessagePreferences,
        },
        validationSchema: Yup.object({
            customizeResponseDescription: Yup.string()
            .required("Required")
            .max(
              customizeResponseMaxCharAllowed,
              `Must be ${customizeResponseMaxCharAllowed} characters or less`
            ),
        }),
        onSubmit: async (values, { resetForm }) => {
          setIsLoading(true);
          try {
            const response = await accessTokenInterceptor.put(
                `${user_routes.getUserAttributes}/${userID}`,
                {
                    "custom:messagePreference": values.customizeResponseDescription,
                },
                {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              );
              if (response) {
                dispatch(
                  setUserMessagePreferences({
                      isMessagePreferences: values.customizeResponseDescription,
                  })
                );
                formik.resetForm();
                dispatch(setModalOpen({ name: "" }));
            }
          } catch (error) {
            dispatch(
              setAlert({ title: error.response.data.errorDetails, type: "error" })
            );
            resetForm();
          } finally {
            setIsLoading(false);
          }
        },
      });
      const handleCancel = () => {
        formik.resetForm();
        dispatch(setModalOpen({ name: ""})); 
      }
      const handleUnsaveResponse = () => {
        if(formik.values.customizeResponseDescription.trim() === isMessagePreferences.trim()){
            dispatch(setModalOpen({ name: ""}));
        }else {
            dispatch(setModalOpen({ name: modalNames.confirmationModal }));
        }
    }
      return {
        isLoading,
        formik,
        enableResponseForChats,
        handleChange,
        handleCancel,
        handleUnsaveResponse
    }
}
export default useCustomizeResponse;