import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setAlert } from "store/slice/alertSlice";
import { setModalOpen } from "store/slice/modalSlice";
import { modalNames } from "assets/data/data";
import { auth_routes } from "utilities/apiRoutes";
import { useNavigate } from "react-router-dom";
import { routes } from "routes/Routes";
import { setUserTracking, trackSignUpPageVisit } from "../utilities/mixPanel";
const useSignup = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [
    displayPasswordValidationBullets,
    setDisplayPasswordValidationBullets,
  ] = useState(false);
  const modalName = useSelector((state) => state.modal.name);
  const accessToken = useSelector((state) => state.userInfo.accessToken);

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string().email("Invalid Email").required("Email is required"),
      password: Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,~`.<>/?]+)(?=.{8,})/,
          " "
        ),
      confirmPassword: Yup.string()
        .required("Confirm password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      const { firstName, lastName, email, password } = values
      trackSignUpPageVisit("Sign Up Page Visit", { isSignUp: 'true' })
      try {
        const response = await axios.post(`${auth_routes.signup}`, {
          firstName,
          lastName,
          email,
          password,
          isFirstLoggedIn: "false",
          isChatHistory: "false",
        });
        if (response) {
          setUserTracking(email, firstName, lastName)
          setDisplayPasswordValidationBullets(false);
          setIsLoading(false);
          resetForm();
          dispatch(
            setModalOpen({
              name: modalNames.verified,
            })
          );
        }
      } catch (error) {
        setIsLoading(false);
        dispatch(
          setAlert({ type: "error", title: error.response.data.errorDetails })
        );
        resetForm();
      }
    },
  });
  useEffect(() => {
    if (accessToken) navigate(routes.main);

    formik.resetForm();
    setDisplayPasswordValidationBullets(false);
  }, []);

  return {
    isLoading,
    formik,
    displayPasswordValidationBullets,
    modalName,
    setDisplayPasswordValidationBullets,
  };
};
export default useSignup;
