import { useDispatch } from "react-redux";
import useRefreshToken from "./useRefreshToken";
import { useSelector } from "react-redux";
import { user_routes } from "utilities/apiRoutes";
import { setModalOpen } from "store/slice/modalSlice";
import { setUserChatHistory, setUserDataConsent } from "store/slice/userInfoSlice";
import { setAlert } from "store/slice/alertSlice";

const useDataConsent = () => {
   const dispatch = useDispatch();
   const { accessTokenInterceptor } = useRefreshToken();
   const { accessToken, firstName, lastName, phoneNumber, userID } =
    useSelector((state) => state.userInfo);
   const handleDataConsent = async (chatHistory) =>{
    try {
        const response = await accessTokenInterceptor.put(
          `${user_routes.getUserAttributes}/${userID}`,
          {
            "custom:firstName": firstName,
            "custom:lastName": lastName,
            phone_number:
              phoneNumber === "undefined" ? "" : phoneNumber,
              "custom:isChatHistory": chatHistory ? "true" : "false",
              "custom:isFirstLoggedIn": "true",
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response) {
          dispatch(
            setUserDataConsent({
                isDataConsent: "true",
            })
          );
          dispatch(
            setUserChatHistory({
                isChatHistory: chatHistory ? "true" : "false",
            })
          );
          dispatch(setModalOpen({ name: "" }));
        }
      } catch (error) {
        if (error) {
          dispatch(
            setAlert({
              type: "error",
              title: error.response?.data.errorDetails,
            })
          );
        }
      }
   }
   return {
    handleDataConsent
   }
}
export default useDataConsent;