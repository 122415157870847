import { createSlice } from "@reduxjs/toolkit";
// types of alerts are success , error , warning , info
const initialState = {
  title: "",
  lightTheme: true,
  type: "error",
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlert: (state, action) => {
      state.title = action.payload.title;
      state.lightTheme = action.payload.lightTheme || true;
      state.type = action.payload.type || "success";
    },
  },
});

export const { setAlert } = alertSlice.actions;

export default alertSlice.reducer;
