import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useRefreshToken from "./useRefreshToken";
import { reva_prompts } from "utilities/apiRoutes";
import { setConversation } from "../store/slice/conversationSlice";
import { setModalOpen } from "../store/slice/modalSlice";

const useViewPrompts = (isVisible) => {
    const dispatch = useDispatch()
    const { accessTokenInterceptor } = useRefreshToken();
    const { accessToken } = useSelector((state) => state.userInfo);
    const [prompts, setPrompts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const response = await accessTokenInterceptor.get(`${reva_prompts}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (response) {
                setPrompts(response.data);
                setIsLoading(false);

            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (isVisible) {
            fetchData();
        }
    }, [isVisible]);

    const useSearchPrompts = () => {
        const [searchTerm, setSearchTerm] = useState("");

        const filteredPrompts = prompts.filter((prompt) =>
            prompt.content.toLowerCase().includes(searchTerm.toLowerCase())
        );

        return { searchTerm, setSearchTerm, filteredPrompts };
    };

    const handleSelectedPrompt = (content) => {
        dispatch(
            setConversation({
                conversationText: content,
                fieldFocus: true,
            })
        );
        dispatch(setModalOpen(""));
    };

    const { searchTerm, setSearchTerm, filteredPrompts } = useSearchPrompts();

    return {
        searchTerm,
        setSearchTerm,
        filteredPrompts,
        handleSelectedPrompt,
        isLoading,
    };
};

export default useViewPrompts;
