import React from "react";
import VerifiedImage from "assets/svg/verified.svg";

import Modal from "components/Modal";
export const VerifiedModal = (props) => {
  const { isVisible, title } = props;
  return (
    <Modal  show={isVisible} size={"md"}>
      <div className="d-flex flex-column">
        <span className="m-auto mb-3">
          <img src={VerifiedImage} alt="profile" />
        </span>
      </div>
      <div className="d-flex flex-column m-auto text-center  ">
        <h4 className="text-center mb-1">{title}</h4>
        <p className="mb-3">
        Almost done! An email has been sent to your inbox.
        </p>
      </div>
    </Modal>
  );
};
