import React, { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import FormField from "../../../components/FormField";
import { Spinner } from "react-bootstrap";
import useSignup from "hooks/useSignup";
import logo from "assets/svg/ctc-logo.svg";
import insta from "assets/svg/insta.svg";
import linkd from "assets/svg/link.svg";
import { useGoogleLogin } from "@react-oauth/google";
import useFacebookSSO from "hooks/useFacebookSSO";
import fbIcoon from "assets/svg/icon-facebook.svg";
import googleIcoon from "assets/svg/icon-google.svg";
import { Link } from "react-router-dom";
import AccountLayout from "components/AccountLayout";
import useGoogleSSO from "hooks/useGoogleSSO";
import { ctcInstagram, ctcLinkdIn, ctcWebLink, modalNames } from "assets/data/data";
import VerifiedModal from "../VerifiedModal";
import { routes } from "routes/Routes";
import rega from "assets/svg/rega.svg"
import PasswordValidationBullets from "components/PasswordValidationBullets";
import { trackSignUpPageVisit } from "../../../utilities/mixPanel";
export const Signup = () => {
  const { connectToGoogle } = useGoogleSSO();


  const { connectToFacebook } = useFacebookSSO();

  const handleGoogleLogin = () => {
    trackSignUpPageVisit("Sign Up Page Visit", { isSignUp: true });
    connectToGoogle()
  }

  const handleFacebookLogin = () => {
    trackSignUpPageVisit("Sign Up Page Visit", { isSignUp: true });
    connectToFacebook()
  }

  const {
    formik,
    isLoading,
    displayPasswordValidationBullets,
    modalName,
    setDisplayPasswordValidationBullets,
  } = useSignup();



  useEffect(() => {
    trackSignUpPageVisit("Sign Up Page Visit");

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        trackSignUpPageVisit("Sign Up Page Visit");
      } else {
        trackSignUpPageVisit("Sign Up Page Visit");
      }
    };

    window.addEventListener("beforeunload", () => {
      trackSignUpPageVisit("Sign Up Page Visit");
    });

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", () => { });
      trackSignUpPageVisit("Sign Up Page Visit");
    };
  }, []);



  return (
    <>
      <VerifiedModal
        isVisible={modalName === modalNames.verified}
        title="Please Check Your Email"
      />

      <AccountLayout>
        <div className="row mb-3">
          <div className="col text-center sub-heading-auth">
            <div className="p-2">
              <img className="mobile-icon" src={rega} alt="rega"/>
            </div>
            <h3 className="text-primary fw-semibold mb-2 mt-2">Sign up</h3>
            <p className="fw-semibold">Welcome! Please enter your details.</p>
          </div>
        </div>
        <Form
          noValidate
          className="w-100 d-flex justify-content-center flex-column align-items-center"
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <Row>
            <Form.Group
              className="mb-3"
              as={Col}
              md="6"
              xs="12"
              controlId="validationCustom02"
            >
              <FormField
                label="First Name"
                type="text"
                placeholder="First Name"
                isInvalid={formik.errors.firstName}
                isValid={formik.touched.firstName && !formik.errors.firstName}
                error={formik.errors.firstName}
                {...formik.getFieldProps("firstName")}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              as={Col}
              md="6"
              xs="12"
              controlId="validationCustom02"
            >
              <FormField
                label="Last Name"
                type="text"
                placeholder="Last Name"
                isInvalid={formik.errors.lastName}
                isValid={formik.touched.lastName && !formik.errors.lastName}
                error={formik.errors.lastName}
                {...formik.getFieldProps("lastName")}
              />
            </Form.Group>
          </Row>
          <Form.Group
            className="mb-3"
            as={Col}
            xs="12"
            controlId="validationCustom02"
          >
            <FormField
              label="Email"
              type="Email"
              placeholder="my@email.com"
              isInvalid={formik.errors.email}
              isValid={formik.touched.email && !formik.errors.email}
              error={formik.errors.email}
              {...formik.getFieldProps("email")}
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            as={Col}
            xs="12"
            controlId="validationCustom01"
          >
            <FormField
              label="Password"
              type="password"
              placeholder="Password"
              className="field-icon"
              onFocus={() => setDisplayPasswordValidationBullets(true)}
              isInvalid={formik.errors.password}
              isValid={formik.touched.password && !formik.errors.password}
              {...formik.getFieldProps("password")}
              error={formik.errors.password}
            />

            {displayPasswordValidationBullets ? (
              <PasswordValidationBullets password={formik.values.password} />
            ) : null}
          </Form.Group>
          <Form.Group
            className="mb-3"
            as={Col}
            xs="12"
            controlId="validationCustom01"
          >
            <FormField
              label="Confirm Password"
              type="password"
              placeholder="Confirm Password"
              className="field-icon"
              isInvalid={formik.errors.confirmPassword}
              isValid={
                formik.touched.confirmPassword && !formik.errors.confirmPassword
              }
              {...formik.getFieldProps("confirmPassword")}
              error={formik.errors.confirmPassword}
            />
          </Form.Group>
          <Form.Group className=" formModalRow  mb-3" as={Col} xs="12">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              className="w-100 button-padding"
            >
              {isLoading ? (
                <Spinner animation="border" variant="light" />
              ) : (
                "Sign up"
              )}
            </Button>
          </Form.Group>
        </Form>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <span className="line"></span>
            <span className="or mx-2">or</span>
            <span className="line"></span>
          </div>
        </div>
        <Button
          onClick={() => handleGoogleLogin()}
          type="button"
          variant="white"
          className="btn btn-outline-primary w-100 mb-3 sso-btn"
        >
          <img
            src={googleIcoon}
            alt="google"
            className="icon-position-left width-px-20"
          />
          <span>Sign up With Google</span>
        </Button>

        <Button
          type="button"
          variant="white"
          className="btn btn-outline-primary w-100 sso-btn mb-5"
          onClick={() => handleFacebookLogin()}
        >
          <img
            src={fbIcoon}
            alt="facebook"
            className="icon-position-left width-px-20"
            style={{ marginLeft: "18px" }}
          />
          <span>Sign up With Facebook</span>
        </Button>
        <div className="row text-center account-text">
          <div className="col mb-2">
            <p>
              Already have an account? &nbsp;
              <Link
                to={routes.login}
                className="text-decoration-underline text-primary fw-semibold"
              >
                Log In
              </Link>
            </p>
          </div>
        </div>
        <div className="d-flex gap-3 justify-content-center pt-2">
        <a href={ctcWebLink} target="_blank" rel="noopener noreferrer">
          <img src={logo} alt="logo" style={{width:"100px"}}/></a>
        <a href={ctcInstagram} target="_blank" rel="noopener noreferrer">
          <img src={insta} alt="instagram" style={{width:"25px"}}/></a>
        <a href={ctcLinkdIn} target="_blank" rel="noopener noreferrer">
          <img src={linkd} alt="linkdin" style={{width:"25px"}}/></a>
      </div>
        <div className="d-flex justify-content-center mt-px-12 mb-px-12">
          <a href='https://rega-files.s3.ca-central-1.amazonaws.com/REGATermsofUse2023-8-24.docx.pdf' className='text-primary' target="_blank" rel="noopener noreferrer" >
            <p className='text-primary fs-4'>
              Terms of Use
            </p>
          </a >
          <span className="ms-2 me-2"> | </span>
          <a href='https://rega-files.s3.ca-central-1.amazonaws.com/REGAPrivacyPolicy.pdf' target="_blank" rel="noopener noreferrer">
            <p className='text-primary fs-4'>Privacy Policy</p>
          </a>
        </div>
      </AccountLayout>
    </>
  );
};
