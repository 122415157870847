import React from "react";
import { Modal as Popup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { setModalOpen } from "store/slice/modalSlice";
import Icon from "components/IconMoon/Icon";
import { useSelector } from "react-redux";
import { modalNames } from "assets/data/data";

export function Modal({
  children,
  show,
  title,
  modalBodyClassName,
  showHeaderIcon,
  size,
  showCloseButton = true, // pass the prop false for no need of close icon 
  positiveFeedback = false,
}) {
  const dispatch = useDispatch();
  const { name } = useSelector((state) => state.modal);
  const handleClose = () => {
    if (name === modalNames.deleteAccount) {
      dispatch(setModalOpen({ name: modalNames.accountSettings }));
    } else {
      dispatch(setModalOpen({ name: "" }));
    }
    
  };

  return (
    <Popup
      show={show}
      onHide={handleClose}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="border-radius-2"
    >
      <Popup.Body className={`${modalBodyClassName}`}>
        <div className="text-center d-flex justify-content-start align-items-center position-relative">
          {showHeaderIcon && ( !positiveFeedback ?
            <Icon
              icon="Thumbs-Down"
              className="mr-3"
              size={30}
              color="#CE3E31"
              style={{ marginRight: "12px" }}
            /> : <Icon
            icon="Thumbs-Up"
              className="mr-3"
              size={30}
              color="#00C474"
              style={{ marginRight: "12px" }}/>
          )}
          <h5>{title}</h5>
          {showCloseButton && (
            <Button
              size="lg"
              className="p-0 h-auto w-auto btn-close border-0 btn btn-primary"
              style={{
                top: "0px",
                right: "0px",
                position: "absolute",
                minWidth: "auto",
              }}
              onClick={handleClose}
            >
              <FontAwesomeIcon icon={faClose} style={{ display: "block" }} />
            </Button>
          )}
        </div>
        {children}
      </Popup.Body>
    </Popup>
  );
}
