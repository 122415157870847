import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch } from "react-redux";
import { auth_routes } from "utilities/apiRoutes";
import { setAlert } from "store/slice/alertSlice";
import { setModalOpen } from "store/slice/modalSlice";
import { modalNames } from "assets/data/data";

const useForgotPassword = (isVisible) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const initialValues = {
    email: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid Email").required("Email is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${auth_routes.forgotPassword}`,
          values
        );

        setIsLoading(false);
        if (response) {
          // dispatch(
          //   setAlert({
          //     type: "success",
          //     title: "Code has been sent to your email",
          //   })
          // );
          dispatch(setModalOpen({ name: modalNames.verified }));
        }
        resetForm();
      } catch (error) {
        setIsLoading(false);

        if (
          error.response.data.errorCode === "1001_0001_0005_USER_NOT_CONFIRMED"
        ) {
          dispatch(
            setModalOpen({
              popupName: modalNames.enterVerificationCode,
              message: values.email,
            })
          );
        }
        dispatch(
          setAlert({
            type: "error",
            title: error.response.data.errorDetails,
          })
        );

        resetForm();
      }
    },
  });
  useEffect(() => {
    formik.resetForm();
  }, [isVisible]);

  return {
    formik,
    isLoading,
  };
};

export default useForgotPassword;
