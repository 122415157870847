import { reva_conversation_base_url, reva_delete_user } from "utilities/apiRoutes";
import useRefreshToken from "./useRefreshToken";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import { setResetUserInfo } from "store/slice/userInfoSlice";
import { setModalOpen } from "store/slice/modalSlice";
import { setAlert } from "store/slice/alertSlice";
import { modalNames } from "assets/data/data";

const useDeleteAccount = () => {
    const { accessTokenInterceptor } = useRefreshToken();
    const { accessToken } = useSelector((state) => state.userInfo);
    const [ isLoading , setLoading ] = useState(false);
    const dispatch = useDispatch();

    const handleDeleteAccount = async () => {
    try 
    {
        setLoading(true);
        const response = await accessTokenInterceptor.delete(
        `${reva_delete_user}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if(response)
      {
        dispatch(setResetUserInfo());
        dispatch(setModalOpen({ name: "" }));
        Auth.signOut();
      }
      } catch (error) {
        if (error) {
            dispatch(
              setAlert({
                type: "error",
                title: error.response?.data.errorDetails,
              })
            );
          }
      } finally {
        setLoading(false)
      }

    }
    const handleAccountSettingModal = () => {
        dispatch(setModalOpen({ name: modalNames.accountSettings }));
    }
    return {
        handleDeleteAccount,
        isLoading,
        handleAccountSettingModal
    }
}
export default useDeleteAccount;