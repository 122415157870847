import Icon from "components/IconMoon/Icon";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import useSavedConversation from "hooks/useSavedConversation";
import { modalNames } from "assets/data/data";
import { setModalMessage, setModalOpen } from "store/slice/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import ClearConversationByIdModal from "../ClearConversationByIdModal";
import { capitalizationRule } from "utilities/commonMethod";
import useSidebar from "hooks/useSidebar";
export const SavedConversation = ({ createNewConnection }) => {
  const {
    showEditConView,
    handleChangeTitle,
    handleEditConversationTitle,
    handleUpdateConversationTitle,
    handleSelectedConversation,
    setShowEditConView,
  } = useSavedConversation();
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modal.name);
  const { searchedConversation } = useSidebar();
  const emptyConversation = useSelector((state) =>
    state.savedConversations.conversationFlag
  );
  return (
    <>
      <ClearConversationByIdModal
        isVisible={modalNames.clearConversationById === modal}
        disconnect={createNewConnection}
      />
      {searchedConversation ? (
        <>
          {Object.keys(searchedConversation).map(key => (
            <div className={`${searchedConversation[key].length && "mb-px-28"}`}>
              {(searchedConversation[key].length && key === "previousSevenDays") ? <>
                <p className="fw-bold mb-px-2">PREVIOUS 7 DAYS</p>
              </> : (searchedConversation[key].length) ? <>

                <p className="fw-bold">{key.toUpperCase()}</p>
              </>
                : ""}

              {[
                ...new Map(
                  searchedConversation[key]?.map((v) => [v.conversationId, v])
                ).values(),
              ].map((conversation, index) => {
                return (
                  <ul >
                    <li
                      className="mb-2 d-flex align-items-center cursor-pointer"
                      key={conversation.updatedAt}
                      style={{ minHeight: "36px" }}
                    >
                      <div
                        className="d-flex align-items-center gap-2 w-100 "
                        style={{ cursor: "pointer" }}
                      >
                        <div className="d-flex gap-2 align-items-center flex-grow-1">
                          {/* title name changing input */}
                          {showEditConView === conversation.conversationId && (
                            <form
                              onSubmit={() =>
                                handleUpdateConversationTitle(
                                  conversation.conversationId, index, key
                                )
                              }
                            >
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="type here..."
                                defaultValue={capitalizationRule(conversation.name)}
                                onChange={(e) => handleChangeTitle(e)}
                              />
                            </form>
                          )}
                          {/* title and comments icon */}
                          {showEditConView !== conversation.conversationId && (
                            <div
                              className="d-flex align-items-center gap-2"
                              onClick={() =>
                                handleSelectedConversation(
                                  conversation.conversationId,
                                  conversation.name
                                )
                              }
                            >
                              <span
                                style={{ width: "20px" }}
                                className="d-flex align-items-center"
                              >
                                <Icon icon="comments" size={20} />
                              </span>
                              <span className="flex-grow-1 saved-chat-name">
                                {capitalizationRule(conversation?.name?.length <= 15 ? conversation?.name : conversation?.name?.slice(0, 13) + "....")}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="d-flex chat-edit-del gap-2">
                          {/* edit and delete icon */}
                          {showEditConView !== conversation.conversationId && (
                            <>
                              <Button
                                variant="link"
                                className="p-0"
                                onClick={() =>
                                  handleEditConversationTitle(
                                    conversation.conversationId
                                  )
                                }
                              >
                                <Icon icon="pencil" size={16} />
                              </Button>
                              <Button
                                variant="link"
                                className="p-0"
                                onClick={() => {
                                  dispatch(setModalOpen({ name: modalNames.clearConversationById }))
                                  dispatch(setModalMessage({ message: { id: conversation.conversationId, key: key, index: index } }))
                                } // dispatch a Redux action to open the modal
                                }
                              >
                                <Icon icon="trash" size={16} />
                              </Button>
                            </>
                          )}
                          {/* check and cross icon */}
                          {showEditConView === conversation.conversationId && (
                            <>
                              <Button
                                variant="link"
                                className="p-0"
                                type="submit"
                                onClick={() =>
                                  handleUpdateConversationTitle(
                                    conversation.conversationId,
                                    index,
                                    key
                                  )
                                }
                              >
                                <Icon icon="check" size={16} />
                              </Button>
                              <Button
                                variant="link"
                                className="p-0"
                                onClick={() => setShowEditConView("")}
                              >
                                <Icon icon="times" size={16} />
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    </li>
                  </ul >
                );
              })}

            </div>
          ))}
        </>
      ) : null}
      {emptyConversation ? <p>
        Sorry, no chats found.
      </p> : null}
    </>
  );
};
