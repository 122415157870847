import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  fieldFocus: false,
  conversationText: "",
  onGoingConversationId: "",
};

export const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    setConversation: (state, action) => {
      state.fieldFocus = action.payload.fieldFocus;
      state.conversationText = action.payload.conversationText || "";
    },
    setOnGoingConversationId: (state, action) => {
      state.onGoingConversationId = action.payload;
    },
    setResetConversation: (state) => {
      state.fieldFocus = false;
      state.conversationText = "";
      state.onGoingConversationId = "";
    },
  },
});

export const {
  setConversation,
  setOnGoingConversationId,
  setResetConversation,
} = conversationSlice.actions;

export default conversationSlice.reducer;
