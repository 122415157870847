import React from "react";
import ChatBot from "components/ChatBot";
import Main from "components/Main";
import "./Home.scss";

export const Home = ({ setShow, disconnect }) => {
  return (
    <>
      <Main setShow={setShow} disconnect={disconnect}>
        <div className="examples-page h-100">
          <div className="d-flex flex-column h-100 w-100">
            <ChatBot />
          </div>
        </div>
      </Main>
    </>
  );
};
