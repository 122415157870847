import { modalNames } from 'assets/data/data'
import Modal from 'components/Modal'
import useCustomizeResponse from 'hooks/useCustomizeResponse'
import React from 'react'
import { useDispatch } from 'react-redux'
import { setModalOpen } from 'store/slice/modalSlice'

 export const ConfirmModal = ({isVisible}) => {
    const {handleCancel} = useCustomizeResponse()

    const dispatch = useDispatch()
  return (
    <Modal size={"lg"} show={isVisible} title="You have unsaved changes">
     <p className='mt-2'>Are you sure you want to exit? Any changes you made will be permanently lost.</p>
     <div className='w-100 d-flex justify-content-end mt-4'>
        <div>
        <button className='btn border-color-none ps-0 password-btn btn-modal'
          onClick={() => dispatch(setModalOpen({ name: modalNames.customizeResponse }))}>Cancel</button>
        <button className="btn btn-primary btn-modal" onClick={handleCancel}>Yes</button>
        </div> 
     </div>
    </Modal>
  )
}
