import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { user_routes } from "utilities/apiRoutes";
import { setAlert } from "store/slice/alertSlice";
import { setModalOpen } from "store/slice/modalSlice";
import { setUpdateUserDetails, setUserChatHistory } from "store/slice/userInfoSlice";
import useRefreshToken from "./useRefreshToken";
import { modalNames } from "assets/data/data";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { setConversation, setOnGoingConversationId } from "store/slice/conversationSlice";
import { setRemoveSelectedConversation } from "store/slice/saveConversationsSlice";
import { setHeaderTitle } from "store/slice/headerSlice";
import { useNavigate } from "react-router";
import { routes } from "routes/Routes";
const useAccountSettings = (isVisible) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phoneError, setPhoneError] = useState("");
  const { accessTokenInterceptor } = useRefreshToken();
  const { accessToken, email, firstName, lastName, phoneNumber, userID } =
    useSelector((state) => state.userInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [modalSlide,setModalSlice] = useState("generalSettings");
  const [chatHistory,setChatHistory] = useState(false);
  const { isChatHistory } = useSelector((state) => state.userInfo);
  const formik = useFormik({
    initialValues: {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string().required("Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string(),
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        await updateUserData(values);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    },
  });
  useEffect(()=>{
    if(isChatHistory === "true") {
      setChatHistory(true)
    }
  },[isChatHistory])
  useEffect(() => {
    formik.resetForm();
    setPhoneError("");
  }, [isVisible]);
  const handleChange = () =>{
    setChatHistory(!chatHistory);
  }
  const handleTurnOnHistory = () => {
    setChatHistory(true);
    handleChatHistory();
  }
  const handleOpenDeleteAccountModal = () => {
    dispatch(setModalOpen({ name: modalNames.deleteAccount }));
  }
  const handleChatHistory = async () =>{
    try {
      const response = await accessTokenInterceptor.put(
        `${user_routes.getUserAttributes}/${userID}`,
        {
            "custom:isChatHistory": chatHistory ? "true" : "false",
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response) {
        dispatch(
          setUserChatHistory({
              isChatHistory: chatHistory ? "true" : "false",
          })
        );
        dispatch(setModalOpen({ name: "" }));
        dispatch(setOnGoingConversationId(""))
        dispatch(setRemoveSelectedConversation());
        dispatch(setHeaderTitle());
        navigate(routes.main);
        dispatch(
          setConversation({
            fieldFocus: true,
            conversationText: "",
          })
        );
      }
    } catch (error) {
      if (error) {
        dispatch(
          setAlert({
            type: "error",
            title: error.response?.data.errorDetails,
          })
        );
        
      }
    }
  }
 
  const updateUserData = async (values) => {
    if (values.phoneNumber && !isPossiblePhoneNumber(values.phoneNumber)) {
      return;
    }
    try {
      const response = await accessTokenInterceptor.put(
        `${user_routes.getUserAttributes}/${userID}`,
        {
          "custom:firstName": values.firstName,
          "custom:lastName": values.lastName,
          phone_number:
            values.phoneNumber === "undefined" ? "" : values.phoneNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response) {
        dispatch(
          setAlert({
            type: "success",
            title: "Successfully updated your account",
          })
        );
        dispatch(
          setUpdateUserDetails({
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber:
              values.phoneNumber === "undefined" ? "" : values.phoneNumber,
          })
        );
        dispatch(setModalOpen({ name: "" }));
      }
    } catch (error) {
      if (error) {
        dispatch(
          setAlert({
            type: "error",
            title: error.response?.data.errorDetails,
          })
        );
      }
    }
  };

  const handleOpenChangePasswordModal = () => {
    dispatch(setModalOpen({ name: modalNames.updatePassword }));
  };

  const handlePhoneNumberChange = (value) => {
    formik.setFieldValue("phoneNumber", value);
    if (value && !isPossiblePhoneNumber(value)) {
      setPhoneError("Invalid Phone Number");
      return;
    }
    setPhoneError("");
  };

  return {
    formik,
    email,
    isLoading,
    phoneError,
    handleOpenChangePasswordModal,
    handlePhoneNumberChange,
    modalSlide,
    setModalSlice,
    handleChange,
    chatHistory,
    handleChatHistory,
    handleTurnOnHistory,
    handleOpenDeleteAccountModal
  };
};

export default useAccountSettings;
