import { useEffect } from "react";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";

import { FACEBOOK_ID } from "auth/Keys";
const useFacebookSSO = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    if (!window.FB) createScript();

    return () => { };
  }, []);
  const connectToFacebook = () => {
    const fb = window.FB;
    fb.getLoginStatus((response) => {
      if (response.status === "connected") {
        getAWSCredentials(response.authResponse);
      } else {
        fb.login(
          (response) => {
            if (!response || !response.authResponse) {
              return;
            }
            getAWSCredentials(response.authResponse);
          },
          {
            // the authorized scopes
            scope: "public_profile,email",
          }
        );
      }
    });
  };
  const getAWSCredentials = async (response) => {
    const { accessToken } = response;

    if (!accessToken) {
      return;
    }

    const fb = window.FB;
    fb.api("/me", { fields: "name,email" }, async (response) => {
      await Auth.federatedSignIn({ provider: "Facebook" });
    });
  };

  const createScript = () => {
    // load the sdk
    window.fbAsyncInit = fbAsyncInit;
    const script = document.createElement("script");
    script.src = "https://connect.facebook.net/en_US/sdk.js";
    script.async = true;
    script.onload = initFB;
    document.body.appendChild(script);
  };

  const initFB = () => {
    const fb = window.FB;
  };

  const fbAsyncInit = () => {
    // init the fb sdk client
    const fb = window.FB;
    fb.init({
      appId: FACEBOOK_ID,
      cookie: true,
      xfbml: true,
      version: "v2.11",
    });
  };

  return { connectToFacebook };
};

export default useFacebookSSO;
