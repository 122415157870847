export const modalNames = {
  login: "login",
  signup: "signup",
  logout: "logout",
  enterVerificationCode: "verification",
  verified: "verified",
  forgotPassword: "forgotPassword",
  updatePassword: "updatePassword",
  confirmForgotPassword: "confirmForgotPassword",
  clearSavedConversations: "clearSavedConversations",
  clearConversationById: "clearConversationById",
  conversationNegativeFeedback: "conversationNegativeFeedback",
  conversationPositiveFeedback: "conversationPositiveFeedback",
  accountSettings: "accountSettings",
  siteFeedbackOptions: "siteFeedbackOptions",
  bugReportFeedback: "bugReportFeedback",
  generalFeedback: "generalFeedback",
  featureRequest: "featureRequest",
  viewPrompts: "viewPrompts",
  dataConsent: "dataConsent",
  deleteAccount: "deleteAccount",
  customizeResponse: "customizeResponse",
  confirmationModal: "confirmationModal",
};
export const feedbackMaxCharAllowed = 1000;
export const customizeResponseMaxCharAllowed = 1000;
export const ctcWebLink = "https://cityscapetechnology.com/";
export const ctcLinkdIn = "https://www.linkedin.com/company/cityscapetechnology/";
export const ctcInstagram = "https://www.instagram.com/cityscapetechnology/";
export const homeQuestions = [
  {
    id: 0,
    description: "How many homes are for sale in Toronto, Canada?",
  },
  {
    id: 1,
    description: "How many $800K homes are currently on the market in Toronto with a backyard?",
  },
];
export const marketQuestions = [
  {
    id: 0,
    description:
      "What is the average price of condos in Hamilton, ON, Canada?",
  },
  {
    id: 1,
    description: "What is the housing affordability index in Mississauga, Canada?",
  },
];
export const calculationQuestions = [
  {
    id: 0,
    description:
      "What would be the monthly mortgage payment for a home worth 600,000 CAD?",
  },
  {
    id: 1,
    description: "What is my home affordability?",
  },
];
export const excludedWords = [
  "a",
  "an",
  "the",
  "and",
  "but",
  "or",
  "nor",
  "for",
  "on",
  "at",
  "to",
  "from",
  "by",
  "in",
  "of",
  "about",
  "above",
  "across",
  "after",
  "against",
  "along",
  "among",
  "around",
  "as",
  "before",
  "behind",
  "below",
  "beneath",
  "beside",
  "between",
  "beyond",
  "down",
  "during",
  "except",
  "for",
  "from",
  "in",
  "inside",
  "into",
  "near",
  "of",
  "off",
  "on",
  "onto",
  "out",
  "outside",
  "over",
  "past",
  "through",
  "throughout",
  "to",
  "toward",
  "under",
  "underneath",
  "until",
  "up",
  "upon",
  "with",
  "within",
  "without",
  "is",
  "a?",
  "an?",
  "the?",
  "and?",
  "but?",
  "or?",
  "nor?",
  "for?",
  "on?",
  "at?",
  "to?",
  "from?",
  "by?",
  "in?",
  "of?",
  "about?",
  "above?",
  "across?",
  "after?",
  "against?",
  "along?",
  "among?",
  "around?",
  "as?",
  "before?",
  "behind?",
  "below?",
  "beneath?",
  "beside?",
  "between?",
  "beyond?",
  "down?",
  "during?",
  "except?",
  "for?",
  "from?",
  "in?",
  "inside?",
  "into?",
  "near?",
  "of?",
  "off?",
  "on?",
  "onto?",
  "out?",
  "outside?",
  "over?",
  "past?",
  "through?",
  "throughout?",
  "to?",
  "toward?",
  "under?",
  "underneath?",
  "until?",
  "up?",
  "upon?",
  "with?",
  "within?",
  "without?",
  "is?",
  "you?",
  "you",
  "me",
  "me?"
];
export const faqsType = [
  {
    id: 0,
    title: "Seller FAQs",
    description: "Lorem Ipsum dolor set amet ",
    icon: "seller",
    name: "seller",
  },
  {
    id: 1,
    title: "Buyer FAQs",
    description: "Lorem Ipsum dolor set amet ",
    icon: "buyer",
    name: "buyer",
  },
  {
    id: 2,
    title: "LandLord FAQs",
    description: "Lorem Ipsum dolor set amet ",
    icon: "legal-document",
    name: "land-lord",
  },
  {
    id: 3,
    title: "Renter FAQs",
    description: "Lorem Ipsum dolor set amet ",
    icon: "renter",
    name: "renter",
  },
];
export const renterFaq = [
  {
    id: 0,
    title: "Can I get a refund if I made a mistake in my rent payment?",
    description: "Contact your landlord directly to make arrangements.",
  },
  {
    id: 1,
    title: "Do I need to purchase tenant insurance?",
    description: `No, tenant insurance is not mandatory. However, landlords may request it as a condition in the lease.
    Although you are not obligated to get tenant insurance (also known as renter's insurance), it is highly recommended because it can end up saving you a lot of money in the long run. For instance, if another tenant or neighbour caused an accident that resulted in subsequent damage to your personal property, you are responsible for covering the cost to repair or replace your items.
    With tenant insurance, it can cover the damage and losses to your personal property.`,
  },
  {
    id: 2,
    title: "How do I end my lease early?",
    description: `In order to get out of a lease early, you must provide your landlord with a written notice of your intent to terminate the fixed term tenancy agreement earlier than the end date. Afterwards, both you and your landlord must sign the Mutual Agreement to End Tenancy form and each keep a copy.
    If you decide to end your lease early, be prepared to reimburse your landlord for costs associated with re-renting the unit (e.g. advertising or lost rent). As an alternative, you can also ask your landlord for written permission to help them find a new renter either through subletting or assigning the tenancy agreement to someone else.`,
  },
  {
    id: 3,
    title: "How do I get my security deposit back? ",
    description:
      "If you keep the house in good condition and don’t inflict any damages on the property, then you will get your security deposit back at the end of your lease. To ensure that there are no misunderstandings between you and the landlord, be sure to take inventory of any wear-and-tear you see when you move in, preferably via photo. This way no pre-existing damages can be mistakenly blamed on you and you can get your security deposit returned fair and square. Generally, landlords are required to return the security deposit 30 days after the lease ends.",
  },
  {
    id: 4,
    title: "How do I pay rent?",
    description: `Rent rates are set in the lease, as well as the due date and how the rent will be processed by the landlord. As soon as you sign the lease, you’ve legally agreed to the terms, so be sure you fully understand the document before you sign.

    The way you pay your rent varies depending on the landlord’s preferences. Sometimes it’s as simple as sending a check in the mail. But some landlords support online payments or can set up automatic withdrawals so you don’t have to remember the due date. Be sure to reference your lease or ask your landlord how they prefer to receive rent before it comes due.`,
  },
  {
    id: 5,
    title:
      "Am I allowed to ask neighbors about the history of the landlord and the building before I sign the lease?",
    description:
      "No one can dictate who you can and cannot talk to in the building. Feel free to inquire about the history of the landlord and building. But keep in mind that, depending on your approach, this might not look great for you if the word gets back to your potential landlord.",
  },
  {
    id: 6,
    title:
      "Are landlords allowed to come into my rental property without notice?",
    description:
      "In order to enter the rental property, landlords must serve a notice of entry at least 24 hours in advance, but no more than 30 days before the effective date. The notice must state the date, time and purpose for entering. In addition, the time scheduled for entry must be between 8 a.m. to 9 p.m., unless another time is agreed upon. It’s important to know your rights as a tenant.",
  },
  {
    id: 7,
    title:
      "I need to move out early and break the terms of my lease. How does this work?",
    description:
      "Hey, we know that not everything goes as planned. Maybe you need to move back home. Maybe your job has relocated you. Regardless of the reason, breaking the terms of a lease is never an ideal situation. Often a lease will include an early-release clause, which requests one or two months’ rent from the tenant after they vacate. If there are no terms, you’ll need to negotiate with your landlord. Always be straightforward with your landlord about your situation, respectful of their needs, and apologetic. Sometimes a little kindness really does go a long way.",
  },
  {
    id: 8,
    title: "I want make some changes to my apartment decor. How far can I go?",
    description: `Making simple aesthetic changes, like swapping furniture, is not a problem (unless you rented a furnished unit). But if you want to make changes to the apartment itself — like painting or mounting some shelves on the wall — you will need to check the terms of your lease. Many landlords  have a clause in the lease requiring written permission, so even if you receive verbal permission from your landlord, be sure to cover your butt and get a written document specifically telling you what you can and cannot do.

    Foregoing landlord permission can leave you with a financial mess at the end of the lease term if you aren't able to reverse all of the changes. It’s much easier to be straightforward with your landlord and simply ask what kind of changes you’re allowed to make.`,
  },
];
export const landlordFAQ = [
  {
    id: 0,
    title: "How do I check a potential tenant’s credit score?",
    description: `To check a potential tenant's credit score, you can use our Trust Score intelligent rating system.
    This involves a standard credit check conducted by Equifax, which provides a comprehensive picture of the renter's financial credibility based on payment history, credit utilization, bankruptcy index, and so forth.
    In addition to the credit check, Cityscape uses a powerful algorithm to combine a renter's financial record with public and submitted data to assign a Trust Score that allows you to evaluate a renter's reliability and credibility.`,
  },
  {
    id: 1,
    title: "How do I find the right tenant for my rental property?",
    description:
      "The quality of your tenant will influence the success of your business. The right tenant is one who takes care of the rental property and makes rent payments on time. To find a good tenant, you should conduct thorough tenant screening. Ensure you conduct identity checks, check their credit history, and rent payment history.",
  },
  {
    id: 2,
    title: "When can landlords enter a rental unit?",
    description: `In order to enter a rental unit, landlords must provide proper notice to the tenant which involves a written note that specifies the date, time and purpose for entering the property. This must be delivered in-person at least 24 hours prior to entry, and scheduled between 8AM - 9PM (unless you and your tenant have agreed on another time).
    The purpose of entering a rental unit includes one of the following:
    1 - To inspect the property's condition once per month
    2 - To complete repairs or maintenance
    3 - To show the property to prospective tenants or buyers`,
  },
  {
    id: 3,
    title: "What to do if tenant doesn't pay rent?",
    description: `If your tenant doesn't pay rent (i.e. the full amount and on-time) by midnight on the due date, then you can serve a 10 Day Notice to End Tenancy. [NOTE: This must be a written notice.]
    After receiving the notice, the tenant can either pay the overdue amount within five days or dispute it. If the tenant does nothing, then an alternate option is to apply for Landlord Direct Request. This allows landlords to claim for an Order of Possession (a document that allows landlords to force a tenant to move out) and a monetary order (an order that allows landlords to recover unpaid rent and utilities & payment of the filing fee).
    If you don't have all the paperwork to complete the Direct Request process, then you can apply for dispute resolution. `,
  },
  {
    id: 4,
    title: "What should I do if my tenant abandons the rental unit?",
    description: `A tenancy is considered abandoned when rent has not been paid for at least for a month and one of the following conditions applies:
    1 - Tenant has removed their possessions
    2 - Tenant stated that they don't intend to return or certain actions indicate that the tenant does not intend to return (e.g. moving to a care home)
    In the case that the tenant did abandon the rental unit, landlords can apply for dispute resolution to request compensation (e.g. unpaid rent or utilities and additional expenses).`,
  },
  {
    id: 5,
    title: "Can I force my tenant to buy tenant insurance?",
    description:
      "Although tenant insurance is not mandated by law, landlords can require renters to have tenant insurance as part of the lease agreement. However, it's up to the tenant to agree with the term. If your tenant agrees to have insurance, then you have the right to require proof of that insurance whether it's on an annual basis or as a one-off.",
  },
  {
    id: 6,
    title: "What if the tenant refuses to pay rent?",
    description:
      "If the tenant refuses to pay, you can issue them a notice to leave the rental property. Any outstanding rent can be sought through the court. As a precaution, you can take out rent guarantee insurance to insure yourself against rent nonpayment. The insurance company will pay the rent for up to a specific number of months in the event that the tenant is unable to pay rent for any reason. Upon application, you will receive a policy document that sets out the terms and conditions that you must abide by.",
  },
  {
    id: 7,
    title: "What if the tenant causes damage to my property?",
    description:
      "This is arguably the most common worry of rental property owners. Consequently, it is one of the most frequently asked real estate questions. To reduce the risk of property damage, the landlord should collect a security deposit when the tenant moves in. The deposit will cover any damage and also remind the tenant to ensure that the property is in the condition they found it when they move out. If the tenant doesn’t pay for the damage when it occurs, the cost of fixing it will be deducted from the security deposit when the tenancy ends. However, the landlord should allow for fair wear and tear.",
  },
  {
    id: 8,
    title: "How will I determine the rent to charge for my property?",
    description:
      "This is also one of the important real estate questions every investor should know the answer to before advertising their rental property. The rental value of a property is critical. If over-estimated, the property may remain vacant for a long period of time. If it is too low, it may result in negative cash flow. To get a fair estimate of the monthly rental value, you will need to look for comparable properties (rental comps) in the area to see what they are being advertised for. You should also consider the local market conditions.",
  },
  {
    id: 9,
    title: "What information can a landlord ask for?",
    description: `1 - ID 
    Landlords are permitted to ask for the tenant's name and proof of identity. Example documents false Driver's License, Passport, Canadian Citizenship Card, Permanent Resident Card, or any other government-issued photo ID.Contact Information
     
     2 - Credit Check
     Prospective landlords may request a credit check to assess your ability to pay rent. To run a credit check, you must provide your name, address, and date of birth. Landlords may also ask for your driver's license, passport, or social insurance number (SIN). Although this information is not needed, it will allow the landlord to obtain a more detailed report from the organization providing the credit check.
     
     3 - References
     Landlords have the right to inquire about the tenant's eviction history and ask for references. This includes current or previous landlords.
     
     4 - Income and Employment Verification
     If a tenant is unable to provide an adequate reference, a landlord can request income and employment verification. Example documents false T4, Pay Stub, Employment Letter, Bank Statements, Post-dated Cheques
     Landlords in B.C. are allowed to collect post-dated cheques from a tenant, but this is not the case in other Canadian provinces such as Quebec. In order to request post-dated cheques, it must be stated as a term in the agreement. Additionally, when the tenant moves out, any remaining cheques must be returned.
     The same documents can be requested from student tenants, but keep in mind that students may not be able to provide income verification. As an alternative, students are allowed to submit student loan financial statements. However, in most cases, landlords will request a guarantor (i.e. someone who would take over payments if the renter breaks the terms in the rental contract).`,
  },
];
export const sellerFAQ = [
  {
    id: 0,
    title: "Can I fire my real estate agent?",
    description:
      "When you list your home with a real estate agent, you’ll be asked to sign a Listing Agreement. This legal document will outline the price, commission and length of the contract. Typically in Toronto, Listing Agreements are for 90 days. If you agent isn’t performing to your expectations, begin by having an honest conversation with him/her. If that doesn’t resolve your concerns, contact your agent’s Broker of Record – technically your listing agreement is signed with the brokerage and not the agent, and the broker will have the ability to remedy the situation. ",
  },
  {
    id: 1,
    title: "Can I sell my house without a lawyer?",
    description:
      "Parties to a real estate Agreement of Purchase and Sale occasionally ask whether they actually need a lawyer to close the deal. The answer to that question is a resounding yes. On the day of completion, lawyers from both parties would meet at the LRO to exchange documents and keys and register the transfer. Today, with electronic registration, everything from title searches to transfers of land are completed with specialized software. Only lawyers and law firms can gain access to this software, and the switch to electronic registration does not remove the requirement that a lawyer must sign the transfer of land. Having an experienced real estate lawyer review an Agreement of Purchase and sale before it becomes firm is also a good idea, as a lawyer will often catch potential issues that a layperson will miss.",
  },
  {
    id: 2,
    title: "Can I still sell my property as a non-resident Canadian?",
    description:
      "If you are a non-resident of Canada during the purchase process, you may require legal assistance. You might have to apply for a non-residency clearance certificate which would be typically done through an accountant.",
  },
  {
    id: 3,
    title: "Do open houses work? ",
    description:
      "Statistically, open houses account for 4% of home sales, though our team stats are significantly higher (12-15%). As a Seller, you want to make it easy for Buyers to see your home, and open houses are one of the least intimidating ways for Buyers to do that. Of course you’ll have a few nosy neighbours come by your house, but a good real estate agent will be able to concentrate their sales efforts on the real potential Buyers.  In almost every circumstance, we’ll recommend a well-advertised public open house on the first weekend of the sale.",
  },
  {
    id: 4,
    title: "How do you determine how much my home is worth?",
    description: `There are a handful of methods that Realtors use to determine the value of a home.  The most common method to determining the value of a home is by completing a comparative market analysis.  A comparative market analysis is an in-depth evaluation of recently sold “comparable” homes in the past 6-12 months.  A comparative market analysis, also known as a “CMA,” isn’t a crystal ball that determines what a home will sell for, however, if performed by a top Realtor, it should greatly narrow the sale price range.
    A professionally completed “CMA” will take into account many features of not only a home, but also the local area and neighborhood.  Considerations that a professionally completed “CMA” include, but is not limited too:
    - Square footage
    - Number of bedrooms
    - Number of bathrooms
    - Upgrades to kitchen.`,
  },
  {
    id: 5,
    title: "When is the best time to sell my home?",
    description: `If only there was one answer to this question! Historically, February, March and September have been the months with the highest sold price to asking price ratio; those months are notorious for some of the fiercest bidding wars in Toronto. December and January are historically the slowest months, but depending on the type of property you own, having fewer competing properties in slow months might be a huge benefit to you. It is typically best to list your home during a “seller’s market” which we are currently experiencing here in Toronto (2022). A seller’s market is when there are more qualified buyers than available homes for sale. Other factors can affect the sale of the home such as weather, school season, seasonal holidays and more. `,
  },
  {
    id: 6,
    title: "What will help my home sell fast?",
    description:
      "Your property can have all of the bells and whistles and may even be located in a great location but if it isn’t priced right, you will find yourself sitting around waiting for an offer that may or may not come in. It is important to determine an appropriate price for your property through the use of market analysis and comparative sales. In addition to this, pricing your property accurately can help place you in a strong negotiating position.",
  },
  {
    id: 7,
    title:
      "What happens if my house doesn’t sell in a certain amount of time? Am I able to temporarily take it off the market and put it back on at a later date?",
    description:
      "Yes, if your home doesn’t sell and you would like to take it off the market, we can always remove it and re-list it at a later date.",
  },
  {
    id: 8,
    title: "Should I be present during showings at my home?",
    description:
      "Easy question to answer – no!  There are many reasons why sellers should not be present during showings.  The primary reason why you should not be present at showings of your home is potential buyer’s can feel uncomfortable to talk open and freely with their Realtor about your home.  They do not want to say something that could offend you, the seller.  The best idea is to leave shortly before the scheduled showing and come back once you are certain the buyer and their Realtor have left your home.",
  },
  {
    id: 9,
    title: "What are the common closing expenses for home sellers?",
    description:
      "Another popular frequently asked question from home sellers is how much it will cost to sell a home. There are expenses that the buyer will have that the seller will not and vice versa. Typical closing expenses for home sellers include the costs of staging, abstract and title search, instrument survey, real estate commissions, transfer taxes which also are known as revenue stamps, legal fees and potentially, a mortgage penalty.",
  },
];
export const buyerFAQ = [
  {
    id: 0,
    title: "Can I buy a home with no down payment?",
    description:
      "The down payment is an essential part of your purchase. There are private lenders who offer mortgages with zero down, but the interest rate will likely be much higher and the cost to you will be much greater in the long run. This is generally not recommended. Don’t cut corners and risk your home and investment. Save up at least five per cent of the purchase price, and consider reducing your home-buying budget to make it more affordable. Aside from good ol’ scrimping and saving, you can also take advantage of the first-time Home Buyer’s Plan to borrow from your RRSPs – tax free!",
  },
  {
    id: 1,
    title: "Can I get my deposit back if I change my mind?",
    description:
      "Generally speaking, yes. However, if the seller incurs legal fees, mortgage holding costs or is unable to quickly re-list and sell, you may forfeit some or all of your deposit. You could also be sued for the difference between your offer and a lower offer from the next buyer.",
  },
  {
    id: 2,
    title: "Can I get the land transfer tax rebate?",
    description:
      "Only if you are a first-time homebuyer in Ontario and Toronto, Canadian citizen or permanent resident, 18 or older and occupy the home within nine months of buying it. You are disqualified if your spouse has owned a home before, while married to you.",
  },
  {
    id: 3,
    title: "Do I need a real estate agent when buying a property?",
    description:
      "You can buy an investment property by yourself. However, it is strongly recommended that you work with a real estate agent, especially if you are still new to real estate investing. Without an agent, the process can be quite rigorous and time-consuming. An agent will walk you through the whole process of buying a property and ensure that you comply with all the requirements. They will help you to evaluate markets, get pre-approved for a loan, find an attorney, negotiate a deal, close a deal, and much more. However, be sure to properly interview prospective agents so as to choose one that is experienced and a good match for you.",
  },
  {
    id: 4,
    title: "Do I need to do a final walk-through?",
    description:
      "As a buyer, you have the option to perform a final walk-through.  Is a final walk through a requirement?   NO.  Is a final walk through necessary?  YES.  Generally when buying a home several weeks go by between when you last walked through your home.  Lots of things can change during that time.  When doing a final walk through a few things you should check is that furnace is working, the toilets are flushing properly, and there is hot water.",
  },
  {
    id: 5,
    title: "How can I finance a real estate purchase?",
    description:
      "To start, most investors finance the first investment property in much the same way they would any other real estate purchase: by getting a mortgage loan from a reputable lender. In general, getting a traditional mortgage is a relatively simple way to finance buying a property at a competitive interest rate. There also are plenty of alternative financing methods available. You could, for example, consider a hard money loan or transactional funding for wholesaling. However, before turning to either method, do your research so you're prepared for what each type of lending entails.   ",
  },
  {
    id: 6,
    title: "How does the inspection phase work?",
    description:
      "Inspections are another common contingency that buyer’s make their purchase offers subject to.  There are many different types of inspections and tests that a buyer has the right to perform.  In most cases, inspections are at the expense of the buyer.  They have a specified number of days to complete the inspections and also a specified number of days to either remove the inspection contingencies or request the seller address findings from the inspections.",
  },
  {
    id: 7,
    title: "How is a Down Payment Calculated?",
    description:
      "When you’re buying a home, the down payment is calculated as a percentage of the total purchase price. The higher the price, the higher the down payment. You can plug in some numbers into a house down payment calculator found online, to get an idea for how much you’ll need to save up.",
  },
  {
    id: 8,
    title: "How much should I offer the sellers?",
    description:
      "When buying a home, you are the only one who can determine how much you should offer a seller.  Certainly it’s suggested you ask for your Realtors advice and thoughts, but ultimately you are the only person who can determine how much you should offer.",
  },
  {
    id: 9,
    title: "I’m thinking about buying my first home. Where do I start?",
    description:
      "Step 1 is to get pre-approved for a mortgage. A pre-approval lets you know how much you can spend and locks you in at the current interest rate for 90 days or more, allowing you to shop with confidence. This is especially important with a potential interest rate hike by the Bank of Canada in 2018, which may impact your mortgage rate and ultimately, your home-buying budget.",
  },
];
export const generalFAQ = [
  {
    id: 0,
    title: "What is REGA?",
    description: "REGA is an artificial intelligence chatbot, powered by the latest GPT architecture. It is a virtual assistant designed to help you with all your real estate needs. With its advanced language processing capabilities and a vast knowledge base, it can understand your queries and provide you with relevant and up-to-date information about properties, market trends, financing, and much more.",
  },
  {
    id: 1,
    title: "What is the pricing for using REGA?",
    description: "Currently, it is free to use.",
  },
  {
    id: 2,
    title: " Can I see my history of threads?",
    description:
      "Yes, you can view your past conversations within the last 14 days from the left sidebar.",
  },
  {
    id: 3,
    title: "How can I provide feedback on responses?",
    description:
      "You can provide feedback by clicking on the “Thumbs Up” or “Thumbs Down” button for each response.",
  },
  {
    id: 4,
    title: "Where can I find your Privacy Policy and Terms of Use?",
    description: (
      <span>
        Please see our{' '}
        <u><a href='https://rega-files.s3.ca-central-1.amazonaws.com/REGAPrivacyPolicy.pdf' target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        </u>{' '}
        and{' '}
        <u> < a href='https://rega-files.s3.ca-central-1.amazonaws.com/REGATermsofUse2023-8-24.docx.pdf' target="_blank" rel="noopener noreferrer" >
          Terms of Use.
        </a >
        </u>
      </span >
    ),
  },
];
