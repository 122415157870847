export const FACEBOOK_ID = "1272001103490245";
export const GOOGLE_ID =
  "615230642070-a37kflo2sl29gl50j65rdpr4390uuved.apps.googleusercontent.com";

const devEnvironments = ["localhost", "reva-sa-dev.cityscapetechnology.com"];

export const chatbotKey = devEnvironments.includes(window.location.hostname)
  ? process.env.REACT_APP_DEV_CHATBOT_KEY
  : process.env.REACT_APP_CHATBOT_KEY;

let mixPanelKey;

if (window.location.hostname === 'rega.ai') {
  mixPanelKey = process.env.REACT_APP_PROD_MIXPANEL_KEY;
} else if (devEnvironments.includes(window.location.hostname)) {
  mixPanelKey = process.env.REACT_APP_DEV_MIXPANEL_KEY;
} else if (window.location.hostname === 'reva-sa-staging.cityscapetechnology.com') {
  mixPanelKey = process.env.REACT_APP_MIXPANEL_KEY;
} else {
  mixPanelKey = process.env.REACT_APP_DEV_MIXPANEL_KEY;
}

export { mixPanelKey };

