import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { store } from "store/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_ID } from "auth/Keys";
import { BrowserRouter } from "react-router-dom";
import { Amplify } from "aws-amplify";
import Alert from "components/Alert";
import { getAwsConfig } from "utilities/commonMethod";
import "react-phone-number-input/style.css";
Amplify.configure(getAwsConfig());
let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GoogleOAuthProvider clientId={GOOGLE_ID}>
          <Alert />
          <App />
        </GoogleOAuthProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
